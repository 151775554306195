import { Dispatch, SetStateAction, useMemo } from 'react';
import styled from 'styled-components';

import { APP_FONT_12PX_MEDIUM, APP_FONT_22PX_SEMIBOLD, APP_FONT_8PX_REGULAR } from 'mockup-font';
import { useLocalAppProperties } from 'providers';
import { useAppTheme } from '../../hooks';

import { BuilderProductWithPrice } from './ContentGateView';
import { ProductTag } from './ProductTag';
import { getDurationForPrice, getProductTrialLabel, getStandardizedTitle } from './util';

const Card = styled.div<{ $isSelected?: boolean; $highlightHex: string; $disabledHex: string }>`
  max-width: 124px;
  flex-basis: 0;
  flex-grow: 1;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ $isSelected, $highlightHex, $disabledHex }) => ($isSelected ? $highlightHex : $disabledHex)};
  outline-style: solid;
  outline-width: ${({ $isSelected }) => ($isSelected ? '1px' : '0px')};
  outline-color: ${({ $isSelected, $highlightHex, $disabledHex }) => ($isSelected ? $highlightHex : $disabledHex)};
  border-radius: 8px;
  padding: 20px 8px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 8px;
  position: relative;
`;

const FullWidthCard = styled(Card)`
  max-width: 100%;
  width: 100%;
  padding: 20px 16px;
  align-items: flex-start;
  text-align: left;
`;

const DisplayNameWrapper = styled.div<{ $isFullWidth?: boolean }>`
  width: 100%;
  padding-bottom: 8px;
  display: flex;
  flex-direction: ${({ $isFullWidth }) => ($isFullWidth ? 'row' : 'column')};
  ${({ $isFullWidth }) => $isFullWidth && 'justfiy-content: space-between'};
  ${({ $isFullWidth }) => $isFullWidth && 'align-items: flex-start'};
`;

const DisplayNameLabel = styled.div`
  ${APP_FONT_12PX_MEDIUM};
  margin-top: 2px;
  flex-grow: 1;
`;

const PriceWrapper = styled.div<{ $isFullWidth?: boolean }>`
  padding-bottom: 14px;
  ${({ $isFullWidth }) => $isFullWidth && 'display: flex'};
  ${({ $isFullWidth }) => $isFullWidth && 'align-items: flex-end'};
`;

const PriceLabel = styled.div<{ $isFullWidth?: boolean }>`
  ${APP_FONT_22PX_SEMIBOLD};
  word-wrap: break-word;
  max-width: ${({ $isFullWidth }) => ($isFullWidth ? '300px' : '85px')};
  ${({ $isFullWidth }) => !$isFullWidth && 'margin: auto'};
`;

const EquivalentLabel = styled.div<{ $isFullWidth?: boolean }>`
  ${APP_FONT_8PX_REGULAR};
  ${({ $isFullWidth }) => $isFullWidth && 'margin: 0 0 5px 4px'};
`;

const TrialLabel = styled.div`
  ${APP_FONT_8PX_REGULAR};
`;

interface ProductCardProps {
  product: BuilderProductWithPrice;
  productForComparison: BuilderProductWithPrice;
  isSelected: boolean;
  isBto?: boolean;
  isFullWidth?: boolean;
  handleSelect: Dispatch<SetStateAction<string | number | undefined>>;
}

export const ProductCard = ({
  product,
  productForComparison,
  isSelected,
  isBto,
  isFullWidth,
  handleSelect,
}: ProductCardProps) => {
  const { getDesignProperty } = useAppTheme();
  const { properties } = useLocalAppProperties();

  const DisabledItemHEX = getDesignProperty('disabledItemHEX') as string;
  const { HighlightHEX, ShowOfferSalePercentage } = properties;

  const { Id, SubscriptionTrial, CustomDisplayName, DisplayName, price, pricePerDay, SubscriptionDuration } = product;

  const displayName = useMemo(() => {
    if (isBto || properties.ShowSubscriptionExternalTitles === '1') {
      return CustomDisplayName ?? DisplayName;
    }

    return getStandardizedTitle(SubscriptionDuration);
  }, [isBto, CustomDisplayName, DisplayName, SubscriptionDuration, properties]);

  const trialLabel = getProductTrialLabel(SubscriptionTrial);

  const savePercent = useMemo(() => {
    if (ShowOfferSalePercentage !== '1' || !productForComparison?.pricePerDay || !pricePerDay) {
      return undefined;
    }

    const difference = productForComparison.pricePerDay - pricePerDay;
    if (difference <= 0) {
      return undefined;
    }

    const roundedPercentage = Math.round((difference / productForComparison.pricePerDay) * 100);
    return roundedPercentage > 0 ? roundedPercentage : undefined;
  }, [productForComparison, pricePerDay, ShowOfferSalePercentage]);

  const tagText = isBto ? 'LIFETIME ACCESS' : `Save ${savePercent}%`;

  const equivalentPrice = useMemo(() => {
    if (
      !productForComparison ||
      Id === productForComparison.Id ||
      !productForComparison.durationInDays ||
      !pricePerDay
    ) {
      return undefined;
    }

    const pricePerBaseDuration = pricePerDay * productForComparison.durationInDays;

    const durationLabel = getDurationForPrice(productForComparison.SubscriptionDuration);

    if (!durationLabel) {
      return undefined;
    }

    return `$${pricePerBaseDuration.toFixed(2)} / ${durationLabel}`;
  }, [productForComparison, Id, pricePerDay]);

  const handleClick = () => handleSelect(Id);

  if (isFullWidth) {
    return (
      <FullWidthCard
        $isSelected={isSelected}
        $highlightHex={`#${HighlightHEX}`}
        $disabledHex={DisabledItemHEX}
        onClick={handleClick}
      >
        <DisplayNameWrapper $isFullWidth>
          <DisplayNameLabel>{displayName}</DisplayNameLabel>
          {savePercent && <ProductTag text={tagText} isSelected={isSelected} isFullWidth />}
        </DisplayNameWrapper>
        <PriceWrapper $isFullWidth>
          <PriceLabel $isFullWidth>{`$${price ?? 'XX.XX'}`}</PriceLabel>
          {equivalentPrice && <EquivalentLabel $isFullWidth>{equivalentPrice}</EquivalentLabel>}
        </PriceWrapper>
        {trialLabel && <TrialLabel>{trialLabel}</TrialLabel>}
        {isBto && <ProductTag text={tagText} isSelected={isSelected} isFullWidth />}
      </FullWidthCard>
    );
  }

  return (
    <Card
      $isSelected={isSelected}
      $highlightHex={`#${HighlightHEX}`}
      $disabledHex={DisabledItemHEX}
      onClick={handleClick}
    >
      {savePercent && <ProductTag text={tagText} isSelected={isSelected} />}
      <div>
        <DisplayNameWrapper>
          <DisplayNameLabel>{displayName}</DisplayNameLabel>
        </DisplayNameWrapper>
        <PriceWrapper>
          <PriceLabel>{`$${price ?? 'XX.XX'}`}</PriceLabel>
          {equivalentPrice && <EquivalentLabel>{equivalentPrice}</EquivalentLabel>}
        </PriceWrapper>
      </div>
      {trialLabel && <TrialLabel>{trialLabel}</TrialLabel>}
    </Card>
  );
};
