import { ReactNode } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { NEUTRAL_6_COLOUR } from 'theme';
import { DragIcon } from 'icons';
import { BuilderCollection, BuilderVideo } from 'providers';

import { ContentCard } from 'app/modules/build-dragdrop/Builder/components';

const ItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  min-width: 100%;
  height: 57px;
  overflow: hidden;

  padding: 4px 20px;
  margin-bottom: 9px;
`;

const StyledHandle = styled(DragIcon)`
  &&&& {
    font-size: 16px;
    color: ${NEUTRAL_6_COLOUR};
    cursor: grab !important;
  }
`;
const HandleWrapper = styled.div`
  padding-right: 4px;
  flex-grow: 0;
`;

interface CollectionDetailedItemProps {
  item: BuilderVideo | BuilderCollection;
  type: string;
  idx: number;
  // context is a static value to describe where the component is used
  // avoids clashes in DraggableIds,
  // should be same for all items that can be rearranged together
  context: string;
  children?: ReactNode | ReactNode[];
}

export const CollectionDetailedItem = ({ item, type, idx, children, ...props }: CollectionDetailedItemProps) => {
  const getDraggableId = () => {
    if (type === 'collection') {
      const collection = item as BuilderCollection;
      return collection.TabId.toString();
    } else {
      const video = item as BuilderVideo;
      return video.VideoId.toString();
    }
  };

  return (
    <Draggable draggableId={getDraggableId()} index={idx}>
      {(provided) => {
        return (
          <ItemContainer ref={provided.innerRef} {...provided.draggableProps} {...props}>
            <HandleWrapper>
              <StyledHandle {...provided.dragHandleProps} />
            </HandleWrapper>
            <ContentCard content={item} type={type} />
            {children}
          </ItemContainer>
        );
      }}
    </Draggable>
  );
};
