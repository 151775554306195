import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Checkbox, Input } from 'antd';

import { CustomButton, Modal } from 'components';
import { FONT_12PX_REGULAR, FONT_16PX_MEDIUM } from 'font';
import { BuildOrSubmitOptions } from 'api';

import { useAdminActions } from '../hooks/useAdminActions';

const Content = styled.div`
  width: 420px;
  height: 200px;
  padding: 32px 32px 0;
`;

const MarginBottom = styled.div`
  margin-bottom: 20px;
`;
const Header = styled.div`
  ${FONT_16PX_MEDIUM};
`;
const Subtitle = styled.div`
  ${FONT_12PX_REGULAR};
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ConfirmButton = styled(CustomButton)`
  &&&& {
    margin-left: 10px;
    width: 70px;
  }
`;

interface BranchModalProps {
  action: { options: BuildOrSubmitOptions; successMessage: string } | undefined;
  setAction: React.Dispatch<
    React.SetStateAction<{ options: BuildOrSubmitOptions; successMessage: string } | undefined>
  >;
}

export const BranchModal = ({ action, setAction }: BranchModalProps) => {
  const { handleBuildOrSubmit } = useAdminActions();
  const [branch, setBranch] = useState<string>('');
  const [useMonorepo, setUseMonorepo] = useState(true);
  const [useMetadata, setUseMetadata] = useState(true);
  const [useManual, setUseManual] = useState(false);
  const platform = action?.options.platform;
  const stage = action?.options.stage;
  const isIOSRelease = platform === 'iOS' && stage === 'Live';

  const reset = () => {
    setBranch('');
    setAction(undefined);
    setUseMonorepo(true);
    setUseMetadata(true);
    setUseManual(false);
  };

  const handleConfirm = useCallback(() => {
    const repository = useMonorepo || platform === 'AndroidTV' ? 'monorepo' : '';
    const metadata = useMetadata ? 'metadata' : '';
    const manual = useManual ? 'manual' : '';

    if (action && platform) {
      handleBuildOrSubmit(
        {
          platform,
          stage: action.options.stage,
          branch: isIOSRelease ? 'main' : branch,
          repository,
          metadata,
          manual,
        },
        action.successMessage,
      );
    }
    reset();
  }, [branch, useMonorepo, useMetadata, useManual, action, platform, handleBuildOrSubmit, reset]);

  const branchOptions = () => {
    return (
      <>
        <MarginBottom>
          <Header>Building {platform}</Header>
          <Subtitle>Please enter your git branch name:</Subtitle>
        </MarginBottom>
        <Input value={branch} onChange={(e) => setBranch(e.target.value)} />
        <Checkbox disabled checked style={{ marginTop: '10px', display: 'none' }}>
          Build from Monorepo
        </Checkbox>
      </>
    );
  };

  const releaseOptions = () => {
    return (
      <>
        <Checkbox
          checked={useMetadata}
          onChange={(e) => setUseMetadata(e.target.checked)}
          style={{ marginTop: '10px' }}
        >
          Update metadata (+screenshots)
        </Checkbox>
        <Checkbox checked={useManual} onChange={(e) => setUseManual(e.target.checked)} style={{ marginTop: '10px' }}>
          Manual release
        </Checkbox>
      </>
    );
  };

  return (
    <Modal
      open={!!action}
      onCancel={reset}
      width="auto"
      $height="100%"
      $radius="6px"
      destroyOnClose
      footer={[
        <Footer key="modal-footer">
          <CustomButton medium secondary onClick={reset}>
            Cancel
          </CustomButton>
          <ConfirmButton medium onClick={handleConfirm} disabled={branch.length === 0 && !isIOSRelease}>
            OK
          </ConfirmButton>
        </Footer>,
      ]}
    >
      <Content>
        {stage !== 'Live' && branchOptions()}
        {isIOSRelease && releaseOptions()}
      </Content>
    </Modal>
  );
};
