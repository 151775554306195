import { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { format, fromUnixTime } from 'date-fns';

import { NEUTRAL_8_COLOUR, PAGE_CONTAINER_WIDE_WIDTH } from 'theme';
import { VidAppUser } from 'api';
import { FONT_12PX_MEDIUM, FONT_14PX_MEDIUM } from 'font';
import { CustomCell, CustomTable, FilterDropdown, PageContainer, SearchBar, TableColumn, TypeLabel } from 'components';
import { useAppProperties, useProducts } from 'hooks';
import { DollarSignIcon, ExportIcon, ExternalLinkIcon, MobileAndroidIcon, RocketIcon } from 'icons';
import { isUserAdmin } from 'utils';

import { useVidAppUsers } from './hooks/useVidAppUsers';
import { CsvButton } from '../../analytics/components';

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SearchBarWrapper = styled.div`
  margin-right: 12px;
`;
const Total = styled.div`
  ${FONT_14PX_MEDIUM};
  color: ${NEUTRAL_8_COLOUR};
`;

const BlueLink = styled.a`
  display: flex;
  align-items: center;
  gap: 2px;
  ${FONT_12PX_MEDIUM};
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const STATUS_MAP: Record<string, string> = {
  active: 'Active',
  trial: 'Free Trial',
  none: 'Inactive',
};
const PLATFORM_MAP: Record<string, string> = {
  Apple: 'iOS',
};

const getDisplayStatus = (status: string | null) => {
  if (!status) {
    return 'Inactive';
  }
  return STATUS_MAP[status] ?? status;
};
const getFilterValue = (value?: string[]) => (value && value.length > 0 ? value.join(',') : undefined);

interface Filters {
  query?: string;
  platform?: string;
  product_id?: string;
  status?: string;
}

type UserFilters = Record<string, string[] | undefined>;
const getExportName = (filters: Filters) => {
  const date = new Date().toISOString().split('T')[0];
  const parts: string[] = [date];

  if (filters.query && filters.query.length > 0) {
    parts.push(filters.query);
  }
  if (filters.platform) {
    parts.push(filters.platform);
  }
  if (filters.product_id && filters.product_id.length > 0) {
    parts.push(filters.product_id.replaceAll(' ', '-'));
  }
  if (filters.status && filters.status.length > 0) {
    parts.push(filters.status);
  }

  // Remove . from filename as it will mess with the extension
  return 'members-' + parts.join('-').toLowerCase().replaceAll('.', '');
};

export const AppUsers = () => {
  const [page, setPage] = useState(1);

  const history = useHistory();
  const { search } = useLocation();
  const { data: properties } = useAppProperties();
  const { data: products } = useProducts();
  const searchParams = new URLSearchParams(search);
  const query = searchParams.get('search') ?? '';
  const [filters, setFilters] = useState<UserFilters>({});
  const appliedFilters = useMemo(() => {
    const obj: Filters = {};
    for (const [key, value] of Object.entries(filters)) {
      const val = getFilterValue(value);
      if (val) {
        obj[key as keyof Filters] = val;
      }
    }
    return obj;
  }, [filters]);

  const appliedOffer = useMemo(() => {
    if (appliedFilters.product_id) {
      return products?.product_by_product_id.get(appliedFilters.product_id);
    }
    return undefined;
  }, [products, appliedFilters]);

  const { data, isError, isLoading } = useVidAppUsers({
    email: query,
    platform: appliedFilters?.platform,
    status: appliedFilters?.status,
    web_price_id: appliedOffer?.ProductIdWeb,
    android_product_id: appliedOffer?.ProductIdAndroid,
    apple_product_id: appliedOffer?.ProductId,
  });

  const availableFilters = useMemo(
    () => [
      {
        title: 'Platform',
        key: 'platform',
        icon: <MobileAndroidIcon />,
        options: [{ name: 'Apple', label: 'iOS' }, { name: 'Android' }, { name: 'Web', label: 'Web' }],
      },
      {
        title: 'Status',
        key: 'status',
        icon: <RocketIcon />,
        options: [
          { name: 'active', label: 'Active' },
          { name: 'trial', label: 'Free Trial', neutral: true },
          { name: 'none', label: 'Inactive', danger: true },
        ],
        multiSelect: true,
        useTypeLabel: true,
      },
      {
        title: 'Offer',
        key: 'product_id',
        icon: <DollarSignIcon />,
        options: products
          ? products.products
              .map((product) => ({
                name: product.ProductId,
                label: product.DisplayName ?? product.CustomDisplayName,
              }))
              .filter(({ label }) => !!label)
              .sort((a, b) => a.label.localeCompare(b.label))
          : [],
      },
    ],
    [products],
  );

  const handleSearch = useCallback(
    (value: string) => {
      const searchInput = value.toLowerCase();
      const searchParam = searchInput !== '' ? `&search=${searchInput}` : '';
      history.replace({
        search: searchParam,
      });
      setPage(1);
    },
    [history],
  );

  const getProductName = useCallback(
    (product_id?: string | null) => {
      if (!products) return '';
      if (!product_id) return '';
      return (
        products?.products?.find(
          (p) =>
            p.SourceProductId === product_id ||
            p.ProductId === product_id ||
            p.ProductIdWeb === product_id ||
            p.ProductIdAndroid === product_id,
        )?.DisplayName || ''
      );
    },
    [products],
  );

  const columns: TableColumn<Omit<VidAppUser, 'id'> & { id: string }>[] = useMemo(
    () => [
      {
        heading: 'Name',
        render: ({ first_name, last_name }) => <CustomCell>{`${first_name} ${last_name}`}</CustomCell>,
        width: 212,
      },
      {
        heading: 'Email',
        render: ({ email }) => <CustomCell>{email}</CustomCell>,
        width: 'grow',
      },
      {
        heading: 'Offer/Subscription',
        render: ({ store_product_id }) => <CustomCell>{getProductName(store_product_id)}</CustomCell>,
        width: 200,
      },
      {
        heading: 'Platform',
        render: ({ platform }) => <CustomCell>{platform ? PLATFORM_MAP[platform] ?? platform : ''}</CustomCell>,
        width: 80,
      },
      {
        heading: 'Purchase Date',
        render: ({ purchase_date }) => (
          <CustomCell>{purchase_date && format(fromUnixTime(purchase_date), 'MMMM dd, yyyy')}</CustomCell>
        ),
        width: 144,
      },
      {
        heading: 'Status',
        render: ({ status }) => (
          <CustomCell>
            <TypeLabel $danger={status === 'none'} $neutral={status === 'trial'}>
              {getDisplayStatus(status)}
            </TypeLabel>
          </CustomCell>
        ),
        width: 100,
      },
      {
        heading: '',
        render: ({ stripe_id }) => {
          if (!stripe_id) {
            return '';
          }
          // This URL works when you are logged into the Stripe Account that manages the customer
          let url = `https://dashboard.stripe.com/customers/${stripe_id}`;
          if (isUserAdmin() && properties?.StripeAccountID) {
            // This URL works when you are logged into the VidApp Stripe Account that manages VidWeb customers
            url = `https://dashboard.stripe.com/connect/accounts/${properties?.StripeAccountID}/customers/${stripe_id}`;
          }
          return (
            <CustomCell>
              <BlueLink href={url} target="_blank">
                <ExternalLinkIcon />
                Stripe
              </BlueLink>
            </CustomCell>
          );
        },
        width: 82,
      },
    ],
    [properties],
  );

  return (
    <PageContainer heading="Members" isError={isError} contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}>
      <TableHeader>
        <SearchWrapper>
          <SearchBarWrapper>
            <SearchBar placeholder="Search email..." value={query} onSearch={handleSearch} width="293px" />
          </SearchBarWrapper>
          <FilterDropdown
            availableFilters={availableFilters}
            handleChange={(value) => {
              setFilters(value);
              setPage(1);
            }}
            total={Object.keys(appliedFilters).length}
          />
        </SearchWrapper>
        <Row>
          <Total>{!!data && `${data?.users.length} Members`}</Total>
          {data && (
            <CsvButton
              filename={getExportName({
                ...filters,
                product_id: getProductName(filters.product_id?.join('')),
                query,
              })}
              headers={[
                { label: 'First Name', key: 'first_name' },
                { label: 'Last Name', key: 'last_name' },
                { label: 'Email', key: 'email' },
                { label: 'Offer/Subscription', key: 'store_product_id' },
                { label: 'Platform', key: 'platform' },
                { label: 'Purchase Date', key: 'purchase_date' },
                { label: 'Status', key: 'status' },
              ]}
              data={data.users.filter((u) => u.email.includes('@'))} // Ignore redacted emails
            >
              <ExportIcon />
              Export List
            </CsvButton>
          )}
        </Row>
      </TableHeader>
      <CustomTable
        columns={columns}
        data={data?.users?.map((user) => ({ ...user, id: user.id.toString() }))}
        overridePage={page}
        onPageChange={setPage}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};
