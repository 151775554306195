import { useQuery } from 'react-query';
import { useAppBeingEdited } from '../../../../../app-context';
import { searchVidAppUsers, VidAppUserSearchRequest } from '../../../../../api';

type UseVidAppUsersProps = Omit<VidAppUserSearchRequest, 'app_id'>;

export const useVidAppUsers = (props?: UseVidAppUsersProps) => {
  const appId = useAppBeingEdited();
  const { platform, email, status, user_id, web_price_id, android_product_id, apple_product_id } = props ?? {};

  return useQuery(
    ['vidapp_user', appId, platform, email, status, user_id, web_price_id, android_product_id, apple_product_id],
    () => {
      return searchVidAppUsers({
        app_id: appId,
        email,
        platform,
        status,
        user_id,
        web_price_id,
        android_product_id,
        apple_product_id,
      });
    },
    { enabled: !!appId },
  );
};
