import { useCallback } from 'react';

import { useSaveContext, useLocalProducts, BuilderProduct } from 'providers';
import { ContentTitle } from 'components';
import { useAppProperties } from 'hooks';
import { getStandardizedTitle } from 'app/modules/build-dragdrop/Builder/mockup/components/ContentGateViews/util';

interface ExternalTitleProps {
  placeholder?: string;
  isSubscription: boolean;
  subscriptionDuration: string;
}

export const ExternalTitle = ({
  Id,
  CustomDisplayName,
  DisplayName,
  placeholder,
  isSubscription,
  subscriptionDuration,
}: Pick<BuilderProduct, 'Id' | 'CustomDisplayName' | 'DisplayName'> & ExternalTitleProps) => {
  const { setProductValue } = useLocalProducts();
  const { setProductValueToSave } = useSaveContext();
  const { data: appProperties } = useAppProperties();

  const showCustomDisplayName =
    !isSubscription ||
    appProperties?.RolloutNewPurchaseScreens !== '1' ||
    appProperties?.ShowSubscriptionExternalTitles === '1';
  const externalTitle = showCustomDisplayName ? CustomDisplayName : getStandardizedTitle(subscriptionDuration);

  const handleTitleChange = useCallback(
    (title: string) => {
      setProductValue(Id, 'CustomDisplayName', title);
      setProductValueToSave(Id, 'CustomDisplayName', title || DisplayName); // If field is empty, use DisplayName value instead
    },
    [setProductValue, setProductValueToSave, Id],
  );

  return (
    <ContentTitle
      label="External Title"
      size="large"
      onChange={handleTitleChange}
      name={externalTitle}
      placeholder={placeholder}
      disabled={!showCustomDisplayName}
    />
  );
};
