import styled from 'styled-components';

import { S3_BUCKET } from 'api';
import { useAppBeingEdited } from 'app-context';
import { APP_FONT_12PX_REGULAR, APP_FONT_14PX_MEDIUM, APP_FONT_15PX_SEMIBOLD, APP_FONT_22PX_BOLD } from 'mockup-font';
import { BuilderProduct, useLocalAppProperties } from 'providers';
import { CustomColor, DEFAULT_THUMBNAIL, useCustomColor } from 'utils';

import { useApplePriceTiers } from 'hooks/useApplePriceTiers';
import { useMemo } from 'react';
import { useAppTheme } from '../../hooks';
import { useMockupContext } from '../../providers';
import { PurchaseScreen } from './PurchaseScreen';
import { useOffers } from 'app/modules/offers/hooks/useOffers';
import { getProductPricePerDay, getProductDurationInDays, sortProductsByPriceTier } from './util';

const Wrapper = styled.div<{ $backgroundColor: string } & CustomColor>`
  width: 100%;
  min-height: 100%;
  cursor: default;
  color: ${useCustomColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  padding: 0;
`;

const FeatureImageWrapper = styled.div`
  background-image: url(${DEFAULT_THUMBNAIL});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 226px;
`;

interface FeatureImageProps {
  backgroundImage: string;
}

const FeatureImage = styled(FeatureImageWrapper)<FeatureImageProps>`
  background-image: ${(props) => `url(${props.backgroundImage})`};
`;

const ContentWrapper = styled.div`
  padding: 15px 41px;
  text-align: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const Heading1 = styled.div`
  ${APP_FONT_22PX_BOLD};
  margin: 10px 0 5px;
  min-height: 32px;
  max-height: 130px;
  overflow: hidden;
  white-space: pre-wrap;
`;

interface SubheadingProps {
  marginBottom?: string;
  maxHeight?: string;
}

const Subheading = styled(Heading1)<SubheadingProps>`
  ${APP_FONT_12PX_REGULAR};
  margin-top: 10px;
  margin-bottom: ${(props) => props.marginBottom || '26px'};
  min-height: 13px;
  max-height: ${(props) => props.maxHeight || '52px'};
`;

interface ButtonProps {
  highlightColor: string;
}

const Button = styled.div<ButtonProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 48px;
  ${APP_FONT_15PX_SEMIBOLD};
  color: ${(props) => `#${props.highlightColor}`};
  border: ${(props) => `1.5px solid #${props.highlightColor}`};
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 9px 6px;
  white-space: pre-wrap;
`;

const PrimaryButton = styled(Button)<ButtonProps>`
  color: #fff;
  background-color: ${(props) => `#${props.highlightColor}`};
`;

const Restore = styled.div`
  ${APP_FONT_14PX_MEDIUM};
  margin-top: 35px;
`;

interface GateScreenProps {
  heading: string;
  subheading: string;
  featureImage: string;
  button1: string;
  button2: string;
  button3?: string;
  highlightColor: string;
}

const GateScreen = ({
  featureImage,
  heading,
  subheading,
  button1,
  button2,
  button3,
  highlightColor,
}: GateScreenProps) => (
  <>
    <FeatureImageWrapper>{featureImage && <FeatureImage backgroundImage={featureImage} />}</FeatureImageWrapper>
    <ContentWrapper>
      <Heading1>{heading}</Heading1>
      <Subheading>{subheading}</Subheading>
      <PrimaryButton highlightColor={highlightColor}>{button1}</PrimaryButton>
      <Button highlightColor={highlightColor}>{button2}</Button>
      {typeof button3 === 'undefined' ? (
        <Restore>Restore purchases</Restore>
      ) : (
        <Button highlightColor={highlightColor}>{button3}</Button>
      )}
    </ContentWrapper>
  </>
);

export interface BuilderProductWithPrice extends BuilderProduct {
  price?: number;
  pricePerDay?: number;
  durationInDays?: number;
}

interface ProductGroups {
  subscription: BuilderProductWithPrice[];
  purchase: BuilderProductWithPrice[];
}

export const ContentGateView = () => {
  const { getDesignProperty } = useAppTheme();
  const PrimaryItemHEX = getDesignProperty('primaryItemHEX') as string;
  const BackgroundHEX = getDesignProperty('backgroundHEX') as string;
  const { purchaseScreenView } = useMockupContext();
  const { properties } = useLocalAppProperties();
  const { products } = useOffers();
  const appId = useAppBeingEdited();
  const { data: priceTiers } = useApplePriceTiers();

  const activeProducts = useMemo(() => {
    if (!products || !priceTiers) {
      return undefined;
    }

    return Array.from(products.values()).filter(({ ReadyForSale }) => ReadyForSale === 'yes');
  }, [products, priceTiers]);

  const productGroups = useMemo(() => {
    const groups: ProductGroups = { subscription: [], purchase: [] };

    if (activeProducts && priceTiers) {
      activeProducts.forEach((p) => {
        const key = p.ProductType;
        if (['subscription', 'purchase'].includes(key)) {
          const price = priceTiers.find((t) => t.Tier.toString() === p.PriceTier)?.USD;
          const numericPrice = price ? parseFloat(price) : undefined;

          groups[key as keyof ProductGroups].push({
            ...p,
            price: numericPrice,
            pricePerDay: getProductPricePerDay(p, numericPrice),
            durationInDays: getProductDurationInDays(p.SubscriptionDuration),
          });
        }
      });

      groups.subscription = sortProductsByPriceTier(groups.subscription);
      groups.purchase = sortProductsByPriceTier(groups.purchase);
    }

    return groups;
  }, [activeProducts]);

  const {
    HighlightHEX,
    CustomPurchaseScreen_BrowseButtonText: BrowseText,
    CustomPurchaseScreen1_SubscriptionOnly_Text1: Subscription1Heading,
    CustomPurchaseScreen1_SubscriptionOnly_Text2: Subscription1Subheading,
    CustomPurchaseScreen1_SubscriptionOnly_SubscribeButtonText: Subscription1Subscribe,
    CustomPurchaseScreen1_SubscriptionOnly_LoginButtonText: Subscription1Login,
    CustomPurchaseScreen2_SubscriptionOnly_Text1: Subscription2Heading,
    CustomPurchaseScreen2_SubscriptionOnly_Text2: Subscription2Subheading,
    CustomPurchaseScreen1_BuyToOwnOnly_Text1: Purchase1Heading,
    CustomPurchaseScreen1_BuyToOwnOnly_Text2: Purchase1Subheading,
    CustomPurchaseScreen1_BuyToOwnOnly_BuyToOwnButtonText: Purchase1Buy,
    CustomPurchaseScreen1_BuyToOwnOnly_LoginButtonText: Purchase1Login,
    CustomPurchaseScreen2_BuyToOwnOnly_Text1: Purchase2Heading,
    CustomPurchaseScreen2_BuyToOwnOnly_Text2: Purchase2Subheading,
    AndroidFeatureGraphic,
  } = properties;

  const featureImageURL = `${S3_BUCKET}${appId}/images/${AndroidFeatureGraphic}`;

  return (
    <Wrapper $color={PrimaryItemHEX} $backgroundColor={BackgroundHEX}>
      {purchaseScreenView === 'Subscription2' ? (
        <PurchaseScreen
          heading={Subscription2Heading}
          subheading={Subscription2Subheading}
          products={productGroups.subscription}
        />
      ) : purchaseScreenView === 'Purchase1' ? (
        <GateScreen
          featureImage={featureImageURL}
          heading={Purchase1Heading}
          subheading={Purchase1Subheading}
          button1={Purchase1Buy || 'Buy-to-own'}
          button2={Purchase1Login || 'Already a member? Sign in'}
          highlightColor={HighlightHEX}
        />
      ) : purchaseScreenView === 'Purchase2' ? (
        <PurchaseScreen
          heading={Purchase2Heading}
          subheading={Purchase2Subheading}
          products={productGroups.purchase}
          isBto
        />
      ) : (
        <GateScreen
          featureImage={featureImageURL}
          heading={Subscription1Heading}
          subheading={Subscription1Subheading}
          button1={Subscription1Subscribe || 'Join now'}
          button2={BrowseText || 'Explore the app'}
          button3={Subscription1Login || 'Already a member? Sign in'}
          highlightColor={HighlightHEX}
        />
      )}
    </Wrapper>
  );
};
