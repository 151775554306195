import { axiosReact } from './axios';

interface AppUser {
  Id: number;
  UserId: string;
  Email: string;
  LastLoginTs: number;
  DataSource: string;
}

export const searchAppUsers = (app_id: string, user_ids: string[]) => {
  return axiosReact
    .post<{ users: AppUser[] }>('/app_users?page_size=100000', {
      app_id,
      user_ids,
    })
    .then(({ data }) => {
      const USER_BY_USER_ID: Record<string, AppUser> = {};
      for (const user of data.users) {
        USER_BY_USER_ID[user.UserId] = user;
      }
      return { users: data.users, user_by_user_id: USER_BY_USER_ID };
    });
};

export interface VidAppUser {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  stripe_id: string;
  platform: string | null;
  status: string | null;
  purchase_date: number | null;
  store_product_id: string | null;
  created_at: number;
  updated_at: number;
}

export interface VidAppUserSearchRequest {
  app_id: string;
  platform?: string;
  status?: string;
  email?: string;
  user_id?: number;
  android_product_id?: string;
  apple_product_id?: string;
  web_price_id?: string;
}

export const searchVidAppUsers = (body: VidAppUserSearchRequest) => {
  return axiosReact.post<{ users: VidAppUser[] }>('/app_users/vidapp', body).then(({ data }) => {
    return data;
  });
};
