import {
  SOURCE_KAJABI,
  SOURCE_MEMBERPRESS,
  SOURCE_ROKUFEED,
  SOURCE_SHOPIFY,
  SOURCE_USCREEN,
  SOURCE_VHX,
  SOURCE_VIDAPP,
  SOURCE_WISHLIST,
  SOURCE_WOOCOMMERCESUBSCRIPTIONS,
} from 'api';
import { SelectOption } from 'components';

export interface PropertyType {
  type: 'appProperty' | 'secureAppProperty';
  valueType?: 'string' | 'binary';
  isFile?: boolean;
  stripWhitespace?: boolean;
}

export interface Field {
  name: string;
  label: string;
  width?: string;
  type?: 'text' | 'checkbox' | 'select'; // Default is 'text'
  options?: SelectOption[]; // for select
}

// Map of all properties editable in the Integrations form
export const PROPERTIES: Record<string, PropertyType> = {
  // VHX
  VHXSiteURL: { type: 'appProperty', stripWhitespace: true },
  VHXAPI: { type: 'secureAppProperty', stripWhitespace: true },
  // Kajabi
  SourceChannelId: { type: 'appProperty', stripWhitespace: true },
  KajabiSiteURL: { type: 'appProperty', stripWhitespace: true },
  KajabiAdminEmail: { type: 'secureAppProperty', stripWhitespace: true },
  KajabiAdminPassword: { type: 'secureAppProperty', stripWhitespace: true },
  KajabiOtpSecret: { type: 'secureAppProperty', stripWhitespace: true },
  KajabiRecoveryCode: { type: 'secureAppProperty', stripWhitespace: true },
  KajabiAutoSyncEnabled: { type: 'appProperty', valueType: 'binary' },
  // WishList
  WishListUrl: { type: 'secureAppProperty', stripWhitespace: true },
  WishListSecret: { type: 'secureAppProperty', stripWhitespace: true },
  // MemberPress
  MemberPressUrl: { type: 'secureAppProperty', stripWhitespace: true },
  MemberPressUsername: { type: 'secureAppProperty' },
  MemberPressSecret: { type: 'secureAppProperty', stripWhitespace: true },
  MemberPressAPIKey: { type: 'secureAppProperty', stripWhitespace: true },
  // WooCommerce
  WooCommerceSiteUrl: { type: 'secureAppProperty', stripWhitespace: true },
  WooCommerceUsername: { type: 'secureAppProperty' },
  WooCommerceSecret: { type: 'secureAppProperty', stripWhitespace: true },
  WooCommerceConsumerKey: { type: 'secureAppProperty', stripWhitespace: true },
  WooCommerceConsumerSecret: { type: 'secureAppProperty', stripWhitespace: true },
  // Uscreen
  UscreenAdminEmail: { type: 'secureAppProperty', stripWhitespace: true },
  UscreenAdminPassword: { type: 'secureAppProperty', stripWhitespace: true },
  UscreenAPIKey: { type: 'secureAppProperty', stripWhitespace: true },
  UscreenPublisherKey: { type: 'secureAppProperty', stripWhitespace: true },
  UScreenUiApiUrl: { type: 'secureAppProperty', stripWhitespace: true },
  UscreenSyncUnpublishedVideos: { type: 'appProperty', valueType: 'binary' },
  DisableUscreenSync: { type: 'appProperty', valueType: 'binary' },
  // Shopify
  ShopifyStoreID: { type: 'secureAppProperty', stripWhitespace: true },
  ShopifyStorefrontToken: { type: 'secureAppProperty', stripWhitespace: true },
  ShopifyAdminAPIAccessToken: { type: 'secureAppProperty', stripWhitespace: true },
  // WordPress
  WordPressBackendLoginURL: { type: 'secureAppProperty', stripWhitespace: true },
  WordPressBackendLoginEmail: { type: 'secureAppProperty' },
  WordPressBackendPassword: { type: 'secureAppProperty', stripWhitespace: true },
  WordPressApplicationPassword: { type: 'secureAppProperty' },
  WordPressAPIKey: { type: 'secureAppProperty', stripWhitespace: true },
  WordPressSyncEnabled: { type: 'appProperty', valueType: 'binary' },
  // Vimeo
  VimeoToken: { type: 'secureAppProperty', stripWhitespace: true },
  VimeoClientId: { type: 'secureAppProperty', stripWhitespace: true },
  VimeoClientSecret: { type: 'secureAppProperty', stripWhitespace: true },
  // JW Player
  JWPlayerSiteId: { type: 'secureAppProperty', stripWhitespace: true },
  JWPlayerAPISecret: { type: 'secureAppProperty', stripWhitespace: true },
  JWPlayerAPISecretV2: { type: 'secureAppProperty', stripWhitespace: true },
  JWPlayerLiveSiteId: { type: 'secureAppProperty', stripWhitespace: true },
  JWPlayerLiveAPISecretV2: { type: 'secureAppProperty', stripWhitespace: true },
  // Custom API
  CustomAPIURL: { type: 'secureAppProperty', stripWhitespace: true },
  CustomAPIKey: { type: 'secureAppProperty', stripWhitespace: true },
  // Amity
  AmitySecureKey: { type: 'secureAppProperty', stripWhitespace: true },
  AmityRegion: { type: 'appProperty', stripWhitespace: true },
  AmityClientKey: { type: 'appProperty', stripWhitespace: true },
  AmityAdminToken: { type: 'secureAppProperty', stripWhitespace: true },
  // YouTube
  YouTubeChannelId: { type: 'appProperty', stripWhitespace: true },
  YouTubeJsonFilename: { type: 'appProperty', isFile: true },
  // Wistia
  WistiaAPI: { type: 'secureAppProperty', stripWhitespace: true },
  // Sentry
  SentryDSNIOS: { type: 'appProperty', stripWhitespace: true },
  SentryDSNAndroid: { type: 'appProperty', stripWhitespace: true },
  // Firebase
  iOSFirebaseConfigFile: { type: 'appProperty', isFile: true },
  AndroidFirebaseConfigFile: { type: 'appProperty', isFile: true },
  tvOSFirebaseConfigFile: { type: 'appProperty', isFile: true },
  FirebaseEmail: { type: 'appProperty', stripWhitespace: true },
  FirebaseServerKey: { type: 'secureAppProperty', stripWhitespace: true },
  // Facebook
  FacebookAppId: { type: 'appProperty', stripWhitespace: true },
  FacebookClientId: { type: 'appProperty', stripWhitespace: true },
  // Deep Linking
  DeepLinkingAndroidURIScheme: { type: 'appProperty', stripWhitespace: true },
  DeepLinkingIOSURIScheme: { type: 'appProperty', stripWhitespace: true },
  DeepLinkingDefaultLinkDomain: { type: 'appProperty', stripWhitespace: true },
  DeepLinkingAlternateLinkDomain: { type: 'appProperty', stripWhitespace: true },
  DeepLinkingBranchKey: { type: 'secureAppProperty', stripWhitespace: true },
  // Roku Feeds
  RokuFeedURL: { type: 'secureAppProperty', stripWhitespace: true },
  // VidApp Integration
  StripeAccountID: { type: 'appProperty', stripWhitespace: true },
  WebDomain: { type: 'appProperty', stripWhitespace: true },
};

export const VHX_FIELDS: Field[] = [
  {
    name: 'VHXSiteURL',
    label: 'VHX Site URL',
  },
  {
    name: 'VHXAPI',
    label: 'VHX API Key',
  },
];

export const KAJABI_FIELDS: Field[] = [
  {
    name: 'SourceChannelId',
    label: 'Kajabi Site ID',
  },
  {
    name: 'KajabiSiteURL',
    label: 'Kajabi Site Login URL',
  },
  {
    name: 'KajabiAdminEmail',
    label: 'Kajabi Assistant Email',
  },
  {
    name: 'KajabiAdminPassword',
    label: 'Kajabi Assistant Password',
  },
  {
    name: 'KajabiOtpSecret',
    label: 'Kajabi OTP Secret (MFA)',
  },
  {
    name: 'KajabiRecoveryCode',
    label: 'Kajabi Recovery Code (MFA)',
  },
  {
    name: 'KajabiAutoSyncEnabled',
    label: 'Auto Sync Kajabi products',
    type: 'checkbox',
  },
];

export const WISHLIST_FIELDS: Field[] = [
  {
    name: 'WishListUrl',
    label: 'WishList URL',
  },
  {
    name: 'WishListSecret',
    label: 'WishList Secret',
  },
];

export const MEMBERPRESS_FIELDS: Field[] = [
  {
    name: 'MemberPressUrl',
    label: 'MemberPress URL',
  },
  {
    name: 'MemberPressUsername',
    label: 'MemberPress Username',
  },
  {
    name: 'MemberPressSecret',
    label: 'MemberPress Password',
  },
  {
    name: 'MemberPressAPIKey',
    label: 'MemberPress API Key',
  },
];

export const WOOCOMMERCE_FIELDS: Field[] = [
  {
    name: 'WooCommerceSiteUrl',
    label: 'WooCommerce Site URL',
  },
  {
    name: 'WooCommerceUsername',
    label: 'WooCommerce API Username',
  },
  {
    name: 'WooCommerceSecret',
    label: 'WooCommerce Password',
  },
  {
    name: 'WooCommerceConsumerKey',
    label: 'WooCommerce Consumer Key',
  },
  {
    name: 'WooCommerceConsumerSecret',
    label: 'WooCommerce Consumer Secret',
  },
];

export const USCREEN_FIELDS: Field[] = [
  {
    name: 'UscreenAdminEmail',
    label: 'Uscreen Admin Email',
  },
  {
    name: 'UscreenAdminPassword',
    label: 'Uscreen Admin Password',
  },
  {
    name: 'UscreenAPIKey',
    label: 'Uscreen Native Apps API Key',
  },
  {
    name: 'UscreenPublisherKey',
    label: 'Uscreen Publisher API Key',
  },
  {
    name: 'UScreenUiApiUrl',
    label: 'Uscreen API URL',
  },
  {
    name: 'UscreenSyncUnpublishedVideos',
    label: 'Sync Unpublished Videos',
    type: 'checkbox',
  },
  {
    name: 'DisableUscreenSync',
    label: 'Disable Manual Uscreen Sync',
    type: 'checkbox',
  },
];

export const SHOPIFY_FIELDS: Field[] = [
  {
    name: 'ShopifyStoreID',
    label: 'Store ID',
  },
  {
    name: 'ShopifyStorefrontToken',
    label: 'Storefront Access Token',
  },
  {
    name: 'ShopifyAdminAPIAccessToken',
    label: 'Admin API Access Token',
  },
];

export const WORDPRESS_FIELDS: Field[] = [
  {
    name: 'WordPressBackendLoginURL',
    label: 'WordPress Backend Login URL',
  },
  {
    name: 'WordPressBackendLoginEmail',
    label: 'WordPress Backend Login Email',
  },
  {
    name: 'WordPressBackendPassword',
    label: 'WordPress Backend Password',
  },
  {
    name: 'WordPressApplicationPassword',
    label: 'WordPress Application Password',
  },
  {
    name: 'WordPressAPIKey',
    label: 'VidApp Plugin API Key',
  },
  {
    name: 'WordPressSyncEnabled',
    label: 'Show WordPress sync button in library',
    type: 'checkbox',
  },
];

export const VIMEO_FIELDS: Field[] = [
  {
    name: 'VimeoToken',
    label: 'Vimeo API Key / Access Token',
  },
  {
    name: 'VimeoClientId',
    label: 'Vimeo Client Id',
  },
  {
    name: 'VimeoClientSecret',
    label: 'Vimeo Client Secret',
  },
];

export const JWPLAYER_FIELDS: Field[] = [
  {
    name: 'JWPlayerSiteId',
    label: 'JW Player Site ID',
  },
  {
    name: 'JWPlayerAPISecret',
    label: 'JW Player API Secret',
  },
  {
    name: 'JWPlayerAPISecretV2',
    label: 'JW Player API Secret V2',
  },
  {
    name: 'JWPlayerLiveSiteId',
    label: 'JW Player Live Site ID',
  },
  {
    name: 'JWPlayerLiveAPISecretV2',
    label: 'JW Player Live API Secret V2',
  },
];

export const CUSTOM_API_FIELDS: Field[] = [
  {
    name: 'CustomAPIURL',
    label: 'Custom API URL',
  },
  {
    name: 'CustomAPIKey',
    label: 'Custom API Key',
  },
];

export const AMITY_FIELDS: Field[] = [
  {
    name: 'AmitySecureKey',
    label: 'Secure Key',
  },
  {
    name: 'AmityRegion',
    label: 'Region',
    type: 'select',
    options: [
      { name: 'Europe', value: 'eu' },
      { name: 'United States', value: 'us' },
      { name: 'Singapore', value: 'sg' },
    ],
  },
  {
    name: 'AmityClientKey',
    label: 'Client Key',
  },
  {
    name: 'AmityAdminToken',
    label: 'Admin Token',
  },
];

export const YOUTUBE_FIELDS: Field[] = [
  {
    name: 'YouTubeChannelId',
    label: 'YouTube Channel ID',
  },
];

export const WISTIA_FIELDS: Field[] = [
  {
    name: 'WistiaAPI',
    label: 'Wistia API Token',
  },
];

export const ROKU_FEED_FIELDS: Field[] = [
  {
    name: 'RokuFeedURL',
    label: 'Roku Feed URL',
  },
];

export const SENTRY_FIELDS: Field[] = [
  {
    name: 'SentryDSNIOS',
    label: 'iOS Sentry DSN',
    width: '100%',
  },
  {
    name: 'SentryDSNAndroid',
    label: 'Android Sentry DSN',
    width: '100%',
  },
];

export const FIREBASE_FIELDS: Field[] = [
  {
    name: 'FirebaseEmail',
    label: 'Firebase Email',
  },
  {
    name: 'FirebaseServerKey',
    label: 'Push Notifications Key',
  },
];

export const FACEBOOK_FIELDS: Field[] = [
  {
    name: 'FacebookAppId',
    label: 'Facebook App ID',
  },
  {
    name: 'FacebookClientId',
    label: 'Facebook Client ID',
  },
];

export const DEEP_LINKING_FIELDS: Field[] = [
  {
    name: 'DeepLinkingAndroidURIScheme',
    label: 'Android URI Scheme',
  },
  {
    name: 'DeepLinkingIOSURIScheme',
    label: 'iOS URI Scheme',
  },
  {
    name: 'DeepLinkingDefaultLinkDomain',
    label: 'Default Link Domain',
  },
  {
    name: 'DeepLinkingAlternateLinkDomain',
    label: 'Alternate Link Domain',
  },
  {
    name: 'DeepLinkingBranchKey',
    label: 'Branch Key',
  },
];
export const VIDAPP_STANDALONE_FIELDS: Field[] = [
  {
    name: 'StripeAccountID',
    label: 'Stripe Account ID (acct_XXXX)',
  },
  {
    name: 'WebDomain',
    label: 'VidWeb Domain',
  },
];

interface SourceFields {
  fields: Field[];
  heading?: string;
}

export const DATA_SOURCE_FIELDS: Record<string, SourceFields> = {
  [SOURCE_VHX]: { fields: VHX_FIELDS, heading: 'VHX/Vimeo OTT' },
  [SOURCE_KAJABI]: { fields: KAJABI_FIELDS },
  [SOURCE_WISHLIST]: { fields: WISHLIST_FIELDS },
  [SOURCE_MEMBERPRESS]: { fields: MEMBERPRESS_FIELDS },
  [SOURCE_WOOCOMMERCESUBSCRIPTIONS]: { fields: WOOCOMMERCE_FIELDS, heading: 'WooCommerce Subscriptions' },
  [SOURCE_USCREEN]: { fields: USCREEN_FIELDS },
  [SOURCE_SHOPIFY]: { fields: SHOPIFY_FIELDS },
  [SOURCE_ROKUFEED]: { fields: ROKU_FEED_FIELDS },
  [SOURCE_VIDAPP]: { fields: VIDAPP_STANDALONE_FIELDS },
};
