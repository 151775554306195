import { init } from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import { AppProps } from 'index';
import React, { useEffect } from 'react';
import './App.less';

import { AxiosError } from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { handleErrorWithSentry } from 'sentry';

import { AxiosProvider, JWTService, setupPrefetching } from 'api';
import { AppBeingEditedProvider } from 'app-context';
import { AccountDetails, TeamTable } from 'app/modules/account';
import { AnalyticsPage, ProgressTrackingPage, VideoAnalyticsPage } from 'app/modules/analytics';
import { AppStoreScreens } from 'app/modules/app-store';
import { BuildMyApp } from 'app/modules/build-screens';
import { CalendarPage } from 'app/modules/calendar';
import { CircuitConfiguration } from 'app/modules/circuit-workouts';
import { Content } from 'app/modules/content';
import { Dashboard } from 'app/modules/dashboard';
import { DesignScreens } from 'app/modules/design-screens';
import { ContentTagging, FiltersPage } from 'app/modules/filters';
import { MainContainer } from 'app/modules/main-container';
import { PushNotifications } from 'app/modules/push-notifications';
import { Webhooks } from 'app/modules/webhooks';
import { ErrorPage } from 'components';
import { DataSourceProvider, PublishProvider, UnsavedChangedProvider } from 'providers';
import { getEnvironment, getUserEmail } from 'utils';
import { AdminScreens } from './modules/admin-screens';
import { AnalyticsProvider } from './modules/analytics/providers/AnalyticsProvider';
import { VideoAnalyticsProvider } from './modules/analytics/providers/VideoAnalyticsProvider';
import { Builder } from './modules/build-dragdrop';
import { CommentsModerator } from './modules/comments-moderator';
import { CommunityPostsTable } from './modules/community';
import { CommunityMembersTable } from './modules/community/Members/CommunityMembersTable';
import { CommunityModerationTable } from './modules/community/Moderation/CommunityModerationTable';
import { FeatureFlagged } from './modules/feature-flagged';
import { Offers } from './modules/offers';
import { AppStoreSettings } from './modules/app-store-settings';
import { AppUsers } from './modules/app-users';

declare global {
  interface Window {
    builderAPI: {
      URL: string;
      serverlessDataExtractURL: string;
      serverlessBackendURL: string;
      serverlessPurchasingURL: string;
      phpBackendURL: string;
      contentApiURL: string;
      appstoreApiURL: string;
    };
    StripeCheckout: { configure: (input: unknown) => { open: () => unknown } };
    chrome: unknown;
    hj: (a: 'event', b: string) => void;
    immediateRetries: Record<string, number>;
    jwtService: JWTService;
  }
}

const env = getEnvironment();
const sentryEnabled = ['production', 'uat'].includes(env);
//ReactQuery configuration https://react-query.tanstack.com/guides/important-defaults
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000, // 60 second cache for api calls
      retry: (failureCount, error) => (error as AxiosError).response?.status !== 404 && failureCount <= 3,
      onError: (err) => {
        if (sentryEnabled) {
          handleErrorWithSentry(err, { request_type: 'query' });
        }
      },
    },
    mutations: {
      onError: (err) => {
        if (sentryEnabled) {
          handleErrorWithSentry(err, { request_type: 'mutation' });
        }
      },
    },
  },
});

if (sentryEnabled) {
  Sentry.init({
    dsn: 'https://d95c1aef069c480bb173ab75d1d6f4d2@o498273.ingest.sentry.io/4504329642442752',
    environment: env,
    integrations: [new Sentry.BrowserTracing()],
    ignoreErrors: [/.*ResizeObserver.*/, /.*undomanager.*/, 'Large Render Blocking Asset'],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  });
}

// Initialize Amplitude analytics instance for non-staff accounts in production environment
const userEmail = getUserEmail();
if (getEnvironment() === 'production' && !userEmail.includes('@vidapp.com')) {
  init('0cf112e45d971e66a93f1ae0196ececf', userEmail);
}

export const App: React.FunctionComponent<AppProps> = ({ appBeingEdited = 'none' }) => {
  useEffect(() => {
    setupPrefetching();
  }, []);

  Sentry.setTag('app_id', appBeingEdited);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AxiosProvider>
          <AppBeingEditedProvider appBeingEdited={appBeingEdited}>
            <PublishProvider>
              <UnsavedChangedProvider>
                <DataSourceProvider>
                  <MainContainer>
                    <Switch>
                      <Route exact path="/">
                        <AnalyticsProvider>
                          <Dashboard />
                        </AnalyticsProvider>
                      </Route>
                      <Route exact path="/onboarding">
                        <AnalyticsProvider>
                          <Dashboard forceWizardOpen />
                        </AnalyticsProvider>
                      </Route>
                      <Route exact path="/analytics/viewership">
                        <AnalyticsProvider>
                          <AnalyticsPage />
                        </AnalyticsProvider>
                      </Route>
                      <Route exact path="/analytics/engagement">
                        <FeatureFlagged>
                          <AnalyticsProvider>
                            <AnalyticsPage />
                          </AnalyticsProvider>
                        </FeatureFlagged>
                      </Route>
                      <Route exact path="/analytics/video-analytics">
                        <AnalyticsProvider>
                          <VideoAnalyticsProvider>
                            <VideoAnalyticsPage />
                          </VideoAnalyticsProvider>
                        </AnalyticsProvider>
                      </Route>
                      <Route exact path="/analytics/engaged-users">
                        <AnalyticsProvider>
                          <AnalyticsPage />
                        </AnalyticsProvider>
                      </Route>
                      <Route exact path="/analytics/progress-tracking">
                        <ProgressTrackingPage />
                      </Route>
                      <Route exact path="/filters">
                        <FeatureFlagged>
                          <FiltersPage />
                        </FeatureFlagged>
                      </Route>
                      <Route exact path="/content-tagging">
                        <FeatureFlagged>
                          <ContentTagging />
                        </FeatureFlagged>
                      </Route>
                      <Route exact path="/calendar-scheduler">
                        <FeatureFlagged>
                          <CalendarPage />
                        </FeatureFlagged>
                      </Route>
                      <Route exact path="/account/details">
                        <AccountDetails />
                      </Route>
                      <Route exact path="/account/team">
                        <TeamTable />
                      </Route>
                      <Route path="/app-store">
                        <AppStoreScreens />
                      </Route>
                      <Route exact path="/push-notifications">
                        <FeatureFlagged>
                          <PushNotifications />
                        </FeatureFlagged>
                      </Route>
                      <Route exact path="/comments-moderator">
                        <FeatureFlagged>
                          <CommentsModerator />
                        </FeatureFlagged>
                      </Route>
                      <Route exact path="/circuit-configuration">
                        <FeatureFlagged>
                          <CircuitConfiguration />
                        </FeatureFlagged>
                      </Route>
                      <Route path="/content">
                        <Content />
                      </Route>
                      <Route exact path="/offers/:offerId">
                        <Offers />
                      </Route>
                      <Route exact path="/offers">
                        <Offers />
                      </Route>
                      <Route exact path="/app-store-settings">
                        <AppStoreSettings />
                      </Route>
                      <Route exact path="/webhooks">
                        <FeatureFlagged>
                          <Webhooks />
                        </FeatureFlagged>
                      </Route>
                      <Route path="/design">
                        <DesignScreens />
                      </Route>
                      <Route exact path="/community/posts">
                        <FeatureFlagged>
                          <CommunityPostsTable />
                        </FeatureFlagged>
                      </Route>
                      <Route exact path="/community/members">
                        <FeatureFlagged>
                          <CommunityMembersTable />
                        </FeatureFlagged>
                      </Route>
                      <Route exact path="/community/moderation">
                        <FeatureFlagged>
                          <CommunityModerationTable />
                        </FeatureFlagged>
                      </Route>
                      <Route path="/build-my-app">
                        <BuildMyApp />
                      </Route>
                      <Route path="/build-my-tv-app">
                        <BuildMyApp />
                      </Route>
                      <Route exact path="/builder/:tabId">
                        <Builder />
                      </Route>
                      <Route exact path="/builder">
                        <Builder />
                      </Route>
                      <Route exact path="/members">
                        {/* For VidApp Platform apps */}
                        <AppUsers />
                      </Route>
                      <Route path="/admin">
                        <AdminScreens />
                      </Route>
                      <Route path="*">
                        <ErrorPage code={404} />
                      </Route>
                    </Switch>
                  </MainContainer>
                </DataSourceProvider>
              </UnsavedChangedProvider>
            </PublishProvider>
          </AppBeingEditedProvider>
        </AxiosProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};
