import { useState } from 'react';
import { useQuery } from 'react-query';

import { useAxiosInstance, getOneSignalSegments } from 'api';
import { useSecureAppProperties } from 'hooks';

export const useOneSignalSegments = () => {
  const client = useAxiosInstance();
  const { data: secureAppProperties } = useSecureAppProperties();

  const [isError, setIsError] = useState(false);

  return useQuery(
    ['oneSignalSegments', secureAppProperties?.OneSignalAppId],
    () => getOneSignalSegments(client, secureAppProperties?.OneSignalAppId, secureAppProperties?.OneSignalApiKey),
    {
      refetchOnMount: isError ? false : 'always',
      refetchOnWindowFocus: !isError,
      refetchOnReconnect: !isError,
      retry: () => {
        setIsError(true);
        return false;
      },
    },
  );
};
