import { useCallback, useEffect, useState } from 'react';

import { CustomButton, InfoModal, SettingsCheckboxInput, SettingsGroup, SettingsSegmentDivider } from 'components';
import { useLocalAppProperties, useSaveContext } from 'providers';
import styled from 'styled-components';
import { NEUTRAL_8_COLOUR } from 'theme';

type QualityKey = 'DOWNLOAD_4K' | 'DOWNLOAD_1080' | 'DOWNLOAD_720' | 'DOWNLOAD_540' | 'DOWNLOAD_360';

interface QualityValue {
  id: number;
  name: string;
}

type QualityPropertyType = Partial<Record<QualityKey, QualityValue>>;

const QUALITY_OPTIONS_PROPERTY = 'VideoDownloadQualityOptions';
const QUALITY_DEFAULT_PROPERTY = 'VideoDownloadQualityDefault';

interface QualityCheckboxOption {
  key: QualityKey;
  id: number;
  name: string;
}

const QUALITY_OPTIONS: QualityCheckboxOption[] = [
  {
    key: 'DOWNLOAD_4K',
    id: 2160,
    name: 'UHD 4K',
  },
  {
    key: 'DOWNLOAD_1080',
    id: 1080,
    name: 'HD 1080p',
  },
  {
    key: 'DOWNLOAD_720',
    id: 720,
    name: 'HD 720p',
  },
  {
    key: 'DOWNLOAD_540',
    id: 540,
    name: 'SD 540p',
  },
  {
    key: 'DOWNLOAD_360',
    id: 360,
    name: 'SD 360p',
  },
];

const DEFAULT_STATE = {
  DOWNLOAD_4K: false,
  DOWNLOAD_1080: true,
  DOWNLOAD_720: true,
  DOWNLOAD_540: true,
  DOWNLOAD_360: true,
};

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const DefaultButton = styled(CustomButton)<{ $isDefault: boolean }>`
  &&&& {
    margin-bottom: 8px;
    ${({ $isDefault }) => $isDefault && `color: ${NEUTRAL_8_COLOUR}`};

    svg {
      ${({ $isDefault }) => $isDefault && `color: ${NEUTRAL_8_COLOUR}`};
    }
  }
`;

export const VideoDownloadQualitySettings = () => {
  const { properties, setAppProperty } = useLocalAppProperties();
  const { setAppPropertyToSave } = useSaveContext();

  const [hoverKey, setHoverKey] = useState<QualityKey>();
  const [optionsState, setOptionsState] = useState<Record<QualityKey, boolean>>();

  const defaultKey = properties[QUALITY_DEFAULT_PROPERTY] ?? 'DOWNLOAD_720';

  // Initialize from app property if it exists
  useEffect(() => {
    if (!optionsState) {
      if (properties[QUALITY_OPTIONS_PROPERTY]) {
        const obj = {} as Record<QualityKey, boolean>;
        const propertyValue = JSON.parse(properties[QUALITY_OPTIONS_PROPERTY]);

        QUALITY_OPTIONS.forEach(({ key }) => {
          obj[key] = propertyValue[key];
        });
        setOptionsState(obj);
      } else {
        setOptionsState(DEFAULT_STATE);
      }
    }
  }, [properties[QUALITY_OPTIONS_PROPERTY]]);

  const handleCheckboxChange = useCallback(
    (key: QualityKey, checked: boolean) => {
      setOptionsState((prev) => {
        const updatedState = prev as Record<QualityKey, boolean>;
        updatedState[key] = checked;

        const propertyToSave: QualityPropertyType = {};

        QUALITY_OPTIONS.forEach(({ key, id, name }) => {
          if (updatedState[key]) {
            propertyToSave[key] = { id, name };
          }
        });
        setAppProperty(QUALITY_OPTIONS_PROPERTY, JSON.stringify(propertyToSave));
        setAppPropertyToSave(QUALITY_OPTIONS_PROPERTY, JSON.stringify(propertyToSave));

        return updatedState;
      });
    },
    [setOptionsState],
  );

  const handleDefaultChange = useCallback(
    (key: QualityKey) => {
      setAppProperty(QUALITY_DEFAULT_PROPERTY, key);
      setAppPropertyToSave(QUALITY_DEFAULT_PROPERTY, key);
    },
    [setAppProperty, setAppPropertyToSave],
  );

  return (
    <>
      <SettingsGroup
        title="Video Download Quality"
        description="Users in your app have the option to choose the quality they download your videos in. The settings below enable you to set what options they can select from as well as the default quality when the app is first downloaded. If an option is not available to download, the app will download the nearest quality."
      >
        {QUALITY_OPTIONS.map(({ key, name }) => {
          const isChecked = optionsState ? optionsState[key] : DEFAULT_STATE[key];
          const isDefault = key === defaultKey;
          const isHovering = key === hoverKey;
          const defaultButtonVisible = isChecked && (isDefault || isHovering);

          return (
            <Row
              key={key}
              onMouseEnter={() => {
                setHoverKey(key);
              }}
              onMouseLeave={() => {
                setHoverKey(undefined);
              }}
            >
              <SettingsCheckboxInput
                width="105px"
                label={name}
                checked={isChecked}
                onChange={(e) => {
                  if (e.target.checked === false && isDefault) {
                    InfoModal('', 'This option is currently set as default so cannot be turned off', 'error');
                  } else {
                    handleCheckboxChange(key, e.target.checked);
                  }
                }}
              />
              {defaultButtonVisible && (
                <DefaultButton
                  small
                  tertiaryHighlight
                  iconPosition="right"
                  tooltip="This will set the default quality for when an app is first downloaded"
                  disabled={isDefault}
                  $isDefault={isDefault}
                  onClick={() => handleDefaultChange(key)}
                >
                  {isDefault ? 'Default Quality' : 'Set as default'}
                </DefaultButton>
              )}
            </Row>
          );
        })}
      </SettingsGroup>
      <SettingsSegmentDivider />
    </>
  );
};
