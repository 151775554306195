import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { FixedSizeList } from 'react-window';

import { HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_10_COLOUR } from 'theme';
import { FONT_14PX_SEMIBOLD } from 'font';
import { BuilderVideo } from 'providers';
import { ModalSearchBox } from 'components';
import { CheckIcon } from 'icons';

import { ContentCard } from 'app/modules/build-dragdrop/Builder/components';
import { ITEM_TYPE_VIDEO } from '../../../api';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 0 17px 0;
`;

const Heading = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_14PX_SEMIBOLD};
  margin-bottom: 13px;
`;
const Results = styled.div`
  padding-top: 16px;
`;

const VideoWrapper = styled.div`
  padding: 0 20px;
  height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const BlueCheck = styled.div`
  color: ${HIGHLIGHT_PRIMARY_COLOUR};
  font-size: 16px;
`;

const RowSpacing = styled.div`
  padding: 0 20px;
`;

interface VideoOptionsProps {
  height?: number;
  heading?: string;
  availableVideos: BuilderVideo[];
  includedVideos: (BuilderVideo['VideoId'] | string)[]; // Videos in the current collection
  onVideoClick: (videoId: BuilderVideo['VideoId']) => void;
}

export const ModalVideoOptions = ({
  height,
  heading,
  availableVideos,
  onVideoClick,
  includedVideos,
}: VideoOptionsProps) => {
  const [query, setQuery] = useState('');

  const videoOptions = useMemo(() => {
    const lowerQuery = query.toLowerCase();

    if (lowerQuery === '') {
      return availableVideos;
    }

    return (
      availableVideos
        .filter(
          (video) =>
            video.SourceTitle?.toLowerCase()?.includes(lowerQuery) ||
            video.Title?.toLowerCase()?.includes(lowerQuery) ||
            video.SourceId?.toLowerCase().includes(lowerQuery),
        )
        // Sort so exact matches display first, then matches beginning with the query, then any other matches including the query
        .sort((a, b) => {
          const titleA = a.Title.toLowerCase();
          const titleB = b.Title.toLowerCase();

          const isExactMatchA = titleA === lowerQuery;
          const isExactMatchB = titleB === lowerQuery;

          if (isExactMatchA && !isExactMatchB) {
            return -1;
          }
          if (!isExactMatchA && isExactMatchB) {
            return 1;
          }

          const isStartsWithA = titleA.startsWith(lowerQuery);
          const isStartsWithB = titleB.startsWith(lowerQuery);

          if (isStartsWithA && !isStartsWithB) {
            return -1;
          }
          if (!isStartsWithA && isStartsWithB) {
            return 1;
          }

          return a.VideoId.toString().localeCompare(b.VideoId.toString());
        })
    );
  }, [availableVideos, query]);

  return (
    <Container>
      <RowSpacing>
        <Heading>{heading ?? 'All'}</Heading>
        <ModalSearchBox onChange={setQuery} />
      </RowSpacing>
      <Results>
        <FixedSizeList height={height ?? 397} width={377} itemSize={74} itemCount={videoOptions.length}>
          {({ index, style }) => {
            const video = videoOptions[index];
            return (
              <VideoWrapper key={video.VideoId} style={style} onClick={() => onVideoClick(video.VideoId)}>
                <ContentCard content={video} type={ITEM_TYPE_VIDEO} />
                {includedVideos.includes(video.VideoId) && (
                  <BlueCheck>
                    <CheckIcon />
                  </BlueCheck>
                )}
              </VideoWrapper>
            );
          }}
        </FixedSizeList>
      </Results>
    </Container>
  );
};
