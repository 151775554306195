import { useQuery, UseQueryOptions } from 'react-query';

import { DataSource, getSyncTracing, useAxiosInstance } from 'api';
import { getUserEmail } from 'utils';
import { useAppBeingEdited } from 'app-context';

interface SyncTrace {
  ItemID: string;
  DataSource: DataSource;
  AppID: string;
  TaskID: string;
  UpdatedAt: string;
  Progress: string;
  ItemName: string;
  ItemType: string;
  Initiator: string;
  ID: number;
  CreatedAt: string;
  Status: string;
}

interface SyncTracingResponse {
  complete?: SyncTrace[];
  importing?: SyncTrace[];
  resource_downloading?: SyncTrace[];
  extracting?: SyncTrace[];
  failed?: SyncTrace[];
}

export const useSyncTracing = (options?: UseQueryOptions<SyncTracingResponse>) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const email = getUserEmail();

  return useQuery<SyncTracingResponse>(['sync_tracing', appId], () => getSyncTracing(client, appId, email), {
    enabled: !!email && !!appId,
    ...options,
  });
};
