import { SelectOption } from 'components';

export interface PropertyType {
  type: 'appProperty' | 'appBasicInfo' | 'secureAppProperty' | 'template';
  valueType?: 'string' | 'binary';
  isFile?: boolean;
  defaultOn?: boolean;
  stripWhitespace?: boolean;
}

export interface Field {
  name: string;
  label: string;
  type: 'text' | 'select' | 'checkbox' | 'textarea';
  propertyType: 'appBasicInfo' | 'appProperty' | 'secureAppProperty' | 'template';
  placeholder?: string;
  tooltip?: string;
  width?: string;
  options?: SelectOption[];
  defaultVal?: string;
}

// Map of all properties editable in the AdminSettings form
export const PROPERTIES: Record<string, PropertyType> = {
  // General
  DeviceLimit: { type: 'appProperty' },
  OneSignalAppId: { type: 'secureAppProperty', stripWhitespace: true },
  DisplayAllVideosInSearchResults: { type: 'appProperty', valueType: 'binary' },
  PasswordRequiredForLogin: { type: 'appProperty', valueType: 'binary' },
  ShowCommentsForItems: { type: 'appProperty', valueType: 'binary', defaultOn: true },
  ShowCommentsForCollections: { type: 'appProperty', valueType: 'binary', defaultOn: true },
  HideUnpublishedVideos: { type: 'appProperty', valueType: 'binary' },
  HideVidAppInVersionLabel: { type: 'appProperty', valueType: 'binary' },
  CodePushKeyApple: { type: 'appProperty' },
  CodePushKeyAndroid: { type: 'appProperty' },
  CustomSignupScreen_text1: { type: 'appProperty' },
  CustomSignupScreen_text2: { type: 'appProperty' },
  ShowSubscriptionExternalTitles: { type: 'appProperty', valueType: 'binary' },
  Product: { type: 'template' },
  Default: { type: 'template' },
  category: { type: 'template' },
  movie: { type: 'template' },
  playlist: { type: 'template' },
  series: { type: 'template' },
  season: { type: 'template' },
  MinimumRequiredAppleAppVersion: { type: 'appProperty', stripWhitespace: true },
  MinimumSuggestedAppleAppVersion: { type: 'appProperty', stripWhitespace: true },
  MinimumRequiredAndroidAppVersion: { type: 'appProperty', stripWhitespace: true },
  MinimumSuggestedAndroidAppVersion: { type: 'appProperty', stripWhitespace: true },
  CustomFontHeaderApple: { type: 'appProperty', isFile: true },
  CustomFontSubHeaderApple: { type: 'appProperty', isFile: true },
  CustomFontBodyBoldApple: { type: 'appProperty', isFile: true },
  CustomFontBodySemiboldApple: { type: 'appProperty', isFile: true },
  CustomFontBodyApple: { type: 'appProperty', isFile: true },
  CustomCompulsoryTermsLink: { type: 'appProperty', isFile: true },
  // iOS
  ITunesUserId: { type: 'appBasicInfo', stripWhitespace: true },
  ITunesPassword: { type: 'secureAppProperty', stripWhitespace: true },
  ITunesTeamId: { type: 'secureAppProperty', stripWhitespace: true },
  ITunesCompanyName: { type: 'secureAppProperty' },
  ITunesDeveloperTitle: { type: 'secureAppProperty' },
  SharedSecret: { type: 'appProperty', stripWhitespace: true },
  AppleKeyId: { type: 'secureAppProperty', stripWhitespace: true },
  AppleIssuerId: { type: 'secureAppProperty', stripWhitespace: true },
  AppleAuthKey: { type: 'secureAppProperty', isFile: true },
  // Android
  AndroidDeveloperName: { type: 'appProperty' },
  GooglePlayPublicKey: { type: 'appProperty', stripWhitespace: true },
  AndroidDefaultCurrency: { type: 'appProperty' },
  AndroidDefaultLanguage: { type: 'appProperty', stripWhitespace: true },
  AndroidJsonFilename: { type: 'secureAppProperty', isFile: true },
  AndroidStorePassword: { type: 'secureAppProperty', stripWhitespace: true },
  AndroidKeyAlias: { type: 'secureAppProperty', stripWhitespace: true },
  AndroidKeyPassword: { type: 'secureAppProperty', stripWhitespace: true },
  AndroidStoreFile: { type: 'secureAppProperty', isFile: true },
  // Roku
  RokuDeveloperAccountEmail: { type: 'secureAppProperty', stripWhitespace: true },
  RokuDeveloperAccountPassword: { type: 'secureAppProperty', stripWhitespace: true },
  RokuSigningKeyID: { type: 'secureAppProperty', stripWhitespace: true },
  RokuSigningKeyPassword: { type: 'secureAppProperty', stripWhitespace: true },
};

export const GENERAL_FIELDS: Field[] = [
  {
    name: 'DeviceLimit',
    label: 'Maximum Devices Signed In Per Email',
    type: 'select',
    propertyType: 'appProperty',
    tooltip: 'The number of devices you can have signed in per email address',
    options: [
      { name: '1', value: '1' },
      { name: '2', value: '2' },
      { name: '3', value: '3' },
      { name: '4', value: '4' },
      { name: '5', value: '5' },
    ],
  },
  {
    name: 'OneSignalAppId',
    label: 'Push Notification App Id (One Signal)',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
  {
    name: 'DisplayAllVideosInSearchResults',
    label: 'Display All Videos From Library In Search Results',
    type: 'checkbox',
    propertyType: 'appProperty',
  },
  {
    name: 'PasswordRequiredForLogin',
    label: 'Password Required For Login',
    type: 'checkbox',
    propertyType: 'appProperty',
  },
  {
    name: 'ShowCommentsForItems',
    label: 'Show Comments For Items',
    type: 'checkbox',
    propertyType: 'appProperty',
  },
  {
    name: 'ShowCommentsForCollections',
    label: 'Show Comments For Collections',
    type: 'checkbox',
    propertyType: 'appProperty',
  },
  {
    name: 'HideUnpublishedVideos',
    label: 'Hide Published-to-Unpublished Videos/Posts',
    type: 'checkbox',
    propertyType: 'appProperty',
  },
  {
    name: 'HideVidAppInVersionLabel',
    label: 'Hide "VidApp" in Profile screen version label',
    type: 'checkbox',
    propertyType: 'appProperty',
  },
];

export const CODE_PUSH_FIELDS: Field[] = [
  {
    name: 'CodePushKeyApple',
    label: 'Apple Deployment Key',
    type: 'select',
    propertyType: 'appProperty',
    options: [], // Populated dynamically
  },
  {
    name: 'CodePushKeyAndroid',
    label: 'Android Deployment Key',
    type: 'select',
    propertyType: 'appProperty',
    options: [], // Populated dynamically
  },
];

export const SIGN_UP_FIELDS: Field[] = [
  {
    name: 'CustomSignupScreen_text1',
    label: 'Custom Text 1',
    type: 'text',
    propertyType: 'appProperty',
    width: '100%',
  },
  {
    name: 'CustomSignupScreen_text2',
    label: 'Custom Text 2',
    type: 'textarea',
    propertyType: 'appProperty',
    width: '100%',
  },
  {
    name: 'ShowSubscriptionExternalTitles',
    label: 'Use custom External Titles for subscriptions',
    type: 'checkbox',
    propertyType: 'appProperty',
  },
];

export const FORCED_UPDATES_FIELDS: Field[] = [
  {
    name: 'MinimumRequiredAppleAppVersion',
    label: 'Minimum Required App Version (iOS)',
    type: 'text',
    propertyType: 'secureAppProperty',
    placeholder: '1.0',
  },
  {
    name: 'MinimumSuggestedAppleAppVersion',
    label: 'Minimum Suggested App Version (iOS)',
    type: 'text',
    propertyType: 'secureAppProperty',
    placeholder: '1.0',
  },
  {
    name: 'MinimumRequiredAndroidAppVersion',
    label: 'Minimum Required App Version (Android)',
    type: 'text',
    propertyType: 'secureAppProperty',
    placeholder: '1',
  },
  {
    name: 'MinimumSuggestedAndroidAppVersion',
    label: 'Minimum Suggested App Version (Android)',
    type: 'text',
    propertyType: 'secureAppProperty',
    placeholder: '1',
  },
];

export const IOS_FIELDS: Field[] = [
  {
    name: 'ITunesUserId',
    label: 'iTunes User Email',
    type: 'text',
    propertyType: 'appBasicInfo',
  },
  {
    name: 'ITunesPassword',
    label: 'iTunes Password',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
  {
    name: 'ITunesTeamId',
    label: 'iTunes Team ID',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
  {
    name: 'ITunesCompanyName',
    label: 'iTunes Team Name (or Developer Title)',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
  {
    name: 'ITunesDeveloperTitle',
    label: 'Developer Title - App Store',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
  {
    name: 'SharedSecret',
    label: 'Apple Shared Secret',
    type: 'text',
    propertyType: 'appProperty',
  },
  {
    name: 'AppleKeyId',
    label: 'Apple Key ID',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
  {
    name: 'AppleIssuerId',
    label: 'Apple Issuer ID',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
];

export const ANDROID_FIELDS: Field[] = [
  {
    name: 'AndroidDeveloperName',
    label: 'Android Developer Name',
    type: 'text',
    propertyType: 'appProperty',
  },
  {
    name: 'GooglePlayPublicKey',
    label: 'Google Play Public Key',
    type: 'text',
    propertyType: 'appProperty',
  },
  {
    name: 'AndroidDefaultCurrency',
    label: 'Android Default Currency',
    type: 'select',
    propertyType: 'appProperty',
    options: [
      'AED',
      'AUD',
      'BRL',
      'CAD',
      'CHF',
      'CLP',
      'COP',
      'CRC',
      'CZK',
      'DKK',
      'EGP',
      'EUR',
      'GBP',
      'HKD',
      'HUF',
      'IDR',
      'ILS',
      'INR',
      'JPY',
      'KRW',
      'LBP',
      'MXN',
      'MYR',
      'NOK',
      'NZD',
      'PEN',
      'PHP',
      'PKR',
      'PLN',
      'RON',
      'SAR',
      'SEK',
      'SGD',
      'THB',
      'TRY',
      'UAH',
      'USD',
      'UYU',
      'VND',
      'ZAR',
    ].map((o) => ({ name: o, value: o })),
    defaultVal: 'USD',
  },
  {
    name: 'AndroidDefaultLanguage',
    label: 'Android Default Language',
    type: 'text',
    propertyType: 'appProperty',
    placeholder: 'US',
  },
];

export const ANDROID_CUSTOM_FIELDS: Field[] = [
  {
    name: 'AndroidStorePassword',
    label: 'Android Store Password',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
  {
    name: 'AndroidKeyAlias',
    label: 'Android Key Alias',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
  {
    name: 'AndroidKeyPassword',
    label: 'Android Key Password',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
];

export const ROKU_FIELDS: Field[] = [
  {
    name: 'RokuDeveloperAccountEmail',
    label: 'Roku Developer Account Email',
    type: 'text',
    propertyType: 'secureAppProperty',
    tooltip: 'Please enter the email address associated with your Roku developer account.',
  },
  {
    name: 'RokuDeveloperAccountPassword',
    label: 'Roku Developer Account Password',
    type: 'text',
    propertyType: 'secureAppProperty',
    tooltip: 'Please enter the password associated with your Roku developer account.',
  },
  {
    name: 'RokuSigningKeyID',
    label: 'Roku Signing Key ID',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
  {
    name: 'RokuSigningKeyPassword',
    label: 'Roku Signing Key Password',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
];
