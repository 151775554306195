import * as amplitude from '@amplitude/analytics-browser';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Form } from 'antd';
import { addDays, isAfter, isBefore, parse } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useAppBeingEdited } from 'app-context';
import {
  CustomButton,
  DatePicker,
  Dropdown,
  PageContainer,
  SegmentTitle,
  SettingsFormItem,
  SettingsGroup,
  SettingsRadioInput,
  SettingsTextArea,
  SettingsTextInput,
} from 'components';
import { FONT_14PX_REGULAR } from 'font';
import { InfoCircleIcon, PlusIcon, SmileIcon } from 'icons';
import { NEUTRAL_10_COLOUR, NEUTRAL_3_COLOUR, NEUTRAL_5_COLOUR, PAGE_CONTAINER_WIDE_WIDTH } from 'theme';

import { usePushNotifications } from '../hooks/usePushNotifications';
import { PushNotificationLog } from './components/PushNotificationLog';
import { PushNotificationPreview } from './components/PushNotificationPreview';

const segmentationOptions = {
  options: [
    { name: 'All users', value: 'All', marginBottom: '28px' },
    { name: 'Activity', value: 'activity', marginBottom: '16px' },
  ],
};

const activityOptions = {
  options: [
    { name: 'Active users - Last session within the last 7 days', value: 'Active Users' },
    { name: 'Non-active users - Last session more than 7 days ago', value: 'Inactive Users', marginBottom: '40px' },
  ],
};

const deliveryOptions = {
  options: [
    { name: 'Begin sending immediately', value: 'now' },
    { name: 'Begin sending at particular time', value: 'scheduled' },
  ],
  default: 'now',
};

const delayOptions = {
  options: [
    {
      name: 'Send to everyone at the same time',
      value: 'immediate',
      tooltip: 'Initiates delivery to all users at the same time, regardless of their activity or timezone',
    },
    {
      name: 'Intelligent delivery (Recommended)',
      value: 'last-active',
      tooltip:
        'Your notification will be sent within 24 hours of you initiating delivery based on when your user most frequently accesses your app',
    },
    {
      name: 'Send based on users timezone',
      value: 'timezone',
      tooltip:
        "Your notification will be sent at this time based on the user's device timezone. If a timezone has already passed when you start sending this notification, they will get the notification at the set timezone, the next day so it is important to make sure you start sending 24 hours before",
    },
  ],
  default: 'immediate',
};

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 36px;
  border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
`;

const StyledDatePicker = styled(DatePicker)`
  #react-app &&&& {
    margin-top: 4px;
    margin-bottom: 30px;
  }
`;

const ScheduleHeader = styled.div`
  width: 100%;
  height: 22px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const HelpText = styled.div`
  width: 303px;
  height: fit-content;
  color: ${NEUTRAL_10_COLOUR};
  background-color: ${NEUTRAL_3_COLOUR};
  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 6px;
  padding: 12px 12px 12px 34px;
  ${FONT_14PX_REGULAR};
  position: relative;
`;

const InfoIcon = styled(InfoCircleIcon)`
  position: absolute;
  font-size: 16px;
  top: 14px;
  left: 12px;
`;

const StyledRadioInput = styled(SettingsRadioInput)<{ $paddingLeft?: string }>`
  #react-app && {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    ${({ $paddingLeft }) => $paddingLeft && `padding-left: ${$paddingLeft}`};

    label {
      ${FONT_14PX_REGULAR};
      color: ${NEUTRAL_10_COLOUR};
      margin-bottom: 12px;
    }
  }
`;

const TitleFieldWrapper = styled.div`
  position: relative;
  width: 50%;
  margin-bottom: 40px;
`;

const MessageFieldWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 34px;
`;

const TitleEmojiWrapper = styled.div`
  position: absolute;
  bottom: 3px;
  right: 40px;
  z-index: 2;
`;

const MessageEmojiWrapper = styled(TitleEmojiWrapper)`
  bottom: 6px;
  right: 50px;
`;

const EmojiButton = styled(CustomButton)<{ $marginBottom?: string }>`
  &&&& {
    margin-bottom: ${({ $marginBottom }) => $marginBottom || 0};

    .anticon {
      margin-right: 0 !important;
    }
  }
`;

const StyledSettingsTextInput = styled(SettingsTextInput)`
  &&&& input {
    padding-right: 18px !important;
  }
`;

const StyledSettingsTextArea = styled(SettingsTextArea)`
  &&&& textarea {
    padding-right: 80px !important;
  }
`;

const PublishButton = styled(CustomButton)<{ $marginTop: string }>`
  &&&& {
    margin-top: ${({ $marginTop }) => $marginTop};
    margin-bottom: 40px;
  }
`;
const disabledDate = (current: Date) => {
  const yesterday = addDays(new Date(), -1);
  const next30Days = addDays(new Date(), 30);
  return isBefore(current, yesterday) || isAfter(current, next30Days);
};

const getTimeForTimepicker = (delivery_time_of_day?: string) => {
  return delivery_time_of_day
    ? parse(delivery_time_of_day, 'h:mm a', new Date())
    : parse('12:00 AM', 'h:mm a', new Date());
};

export const PushNotifications = () => {
  const {
    form,
    readOnly,
    schedulingEnabled,
    oneSignalNotifications,
    oneSignalSegments,
    submitForm,
    isComposing,
    setIsComposing,
    selectedNotification,
    setSelectedNotification,
    previewValues,
    setPreviewValues,
    segmentation,
    setSegmentation,
    deepLinkingEnabled,
    isLoading,
    isError,
    segmentsIsError,
    onValuesChange,
  } = usePushNotifications();
  const appId = useAppBeingEdited();
  const [datePickerDisabled, setDatePickerDisabled] = useState(true);
  const [timePickerDisabled, setTimePickerDisabled] = useState(true);
  const [cursorPosition, setCursorPosition] = useState(0);

  const singlePageView = readOnly || !schedulingEnabled;

  const sendAmplitudeEvent = (segment: string) => amplitude.track('PN segment options selected', { appId, segment });

  const activitySegments = useMemo(
    () => ({
      activeUsers: oneSignalSegments?.find((s) => s.name === 'Active Users'),
      inactiveUsers: oneSignalSegments?.find((s) => s.name === 'Inactive Users'),
    }),
    [oneSignalSegments],
  );

  // For now we are only including Active Users and Inactive Users as segment options (if they exist)
  const hasDefaultSegments = !segmentsIsError && !!activitySegments.activeUsers && !!activitySegments.inactiveUsers;

  useEffect(() => {
    setSegmentation({ group: 'All', activity: 'Active Users' });
    form.resetFields();
  }, [isComposing]);

  useEffect(() => {
    if (schedulingEnabled) {
      if (selectedNotification) {
        setDatePickerDisabled(false);
        setTimePickerDisabled(selectedNotification.delayed_option !== 'timezone');
      } else {
        setDatePickerDisabled(true);
        setTimePickerDisabled(true);
      }
    }
  }, [schedulingEnabled, selectedNotification, setDatePickerDisabled, setTimePickerDisabled]);

  const composeHeading = `${selectedNotification ? 'Edit' : 'New'} Push Notification`;

  const headingButton = !singlePageView && !isComposing && (
    <CustomButton medium icon={<PlusIcon />} onClick={() => setIsComposing(true)}>
      New Push Notification
    </CustomButton>
  );

  const breadcrumbs =
    isComposing && !singlePageView
      ? {
          items: [
            {
              label: 'Push Notifications',
              handleClick: () => {
                setSelectedNotification(undefined);
                setIsComposing(false);
              },
            },
          ],
          currentScreen: composeHeading,
        }
      : undefined;

  return (
    <PageContainer
      heading={isComposing ? composeHeading : 'Push Notifications'}
      subheading={
        isComposing
          ? undefined
          : 'Create and view push notifications to users who have installed your app and agreed to receive push notifications. Notifications being sent will take up to 24 hours to appear in the "Notifications Feed" of your app.'
      }
      headingButton={headingButton}
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      isError={isError}
      contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}
      mockup={
        (isComposing || singlePageView) && (
          <PushNotificationPreview
            scheduledTime={previewValues.scheduledTime}
            scheduledDate={previewValues.scheduledDate}
            title={previewValues.title}
            message={previewValues.message}
          />
        )
      }
    >
      {isComposing || singlePageView ? (
        <>
          <Form
            name="settings-form"
            onValuesChange={onValuesChange}
            onFinish={submitForm}
            autoComplete="off"
            form={form}
            initialValues={
              selectedNotification
                ? {
                    DeepLink: selectedNotification.url,
                    Delivery: 'scheduled',
                    DeliveryDate: selectedNotification.send_after * 1000,
                    TimeZoneTime: getTimeForTimepicker(selectedNotification.delivery_time_of_day),
                    DelayedOption: selectedNotification.delayed_option ?? 'immediate',
                  }
                : { DeliveryDate: Date.now(), TimeZoneTime: getTimeForTimepicker('12:00 AM') }
            }
          >
            <TitleFieldWrapper>
              <StyledSettingsTextInput
                label="Title"
                maxLength={42}
                value={previewValues.title}
                onSelect={(e) => {
                  const target = e.target as HTMLInputElement;
                  setCursorPosition(target.selectionStart ?? 0);
                }}
                onChange={(e) => setPreviewValues((prevState) => ({ ...prevState, title: e.target.value }))}
              />
              <TitleEmojiWrapper>
                <Dropdown
                  align="start"
                  trigger={
                    <EmojiButton tertiary>
                      <SmileIcon />
                    </EmojiButton>
                  }
                  content={
                    <Picker
                      data={data}
                      theme="light"
                      onEmojiSelect={(emoji: Record<string, string>) => {
                        setPreviewValues((prevState) => ({
                          ...prevState,
                          title: `${prevState.title.slice(0, cursorPosition)}${emoji.native}${prevState.title.slice(
                            cursorPosition,
                          )}`,
                        }));
                        setCursorPosition((prevState) => prevState + 2);
                      }}
                    />
                  }
                />
              </TitleEmojiWrapper>
            </TitleFieldWrapper>
            <MessageFieldWrapper>
              <StyledSettingsTextArea
                label="Message"
                maxLength={150}
                value={previewValues.message}
                onSelect={(e) => {
                  const target = e.target as HTMLInputElement;
                  setCursorPosition(target.selectionStart ?? 0);
                }}
                onChange={(e) => setPreviewValues((prevState) => ({ ...prevState, message: e.target.value }))}
              />
              <MessageEmojiWrapper>
                <Dropdown
                  align="start"
                  trigger={
                    <EmojiButton tertiary $marginBottom="3px">
                      <SmileIcon />
                    </EmojiButton>
                  }
                  content={
                    <Picker
                      data={data}
                      theme="light"
                      onEmojiSelect={(emoji: Record<string, string>) => {
                        setPreviewValues((prevState) => ({
                          ...prevState,
                          message: `${prevState.message.slice(0, cursorPosition)}${
                            emoji.native
                          }${prevState.message.slice(cursorPosition)}`,
                        }));
                        setCursorPosition((prevState) => prevState + 2);
                      }}
                    />
                  }
                />
              </MessageEmojiWrapper>
            </MessageFieldWrapper>
            {schedulingEnabled && (
              <>
                {deepLinkingEnabled && (
                  <SettingsGroup>
                    <SettingsFormItem name="DeepLink" width="50%">
                      <SettingsTextInput label="Deep Link (optional)" placeholder="Paste your URL here" />
                    </SettingsFormItem>
                  </SettingsGroup>
                )}
                {hasDefaultSegments && (
                  <>
                    <Divider />
                    <SegmentTitle title="Segmentation" />
                    <SettingsFormItem name="Segmentation">
                      <StyledRadioInput
                        defaultVal={segmentation.group}
                        options={segmentationOptions.options}
                        onChange={(e) => {
                          if (e.target.value === 'activity') {
                            sendAmplitudeEvent(segmentation.activity);
                          }
                          setSegmentation((prev) => ({ ...prev, group: e.target.value }));
                        }}
                      />
                    </SettingsFormItem>
                    <SettingsFormItem name="Activity">
                      <StyledRadioInput
                        disabled={segmentation.group !== 'activity'}
                        defaultVal={segmentation.activity}
                        options={activityOptions.options}
                        onChange={(e) => {
                          sendAmplitudeEvent(e.target.value);
                          setSegmentation((prev) => ({ ...prev, activity: e.target.value }));
                        }}
                        $paddingLeft="28px"
                      />
                    </SettingsFormItem>
                  </>
                )}
                <Divider />
                <ScheduleHeader>
                  <SegmentTitle title="Schedule" />
                  <HelpText>
                    {previewValues.helpText}
                    <InfoIcon />
                  </HelpText>
                </ScheduleHeader>
                <SettingsFormItem name="Delivery">
                  <StyledRadioInput
                    label="Delivery"
                    defaultVal={deliveryOptions.default}
                    options={deliveryOptions.options}
                    onChange={(e) => setDatePickerDisabled(e.target.value === 'now')}
                  />
                </SettingsFormItem>
                <SettingsFormItem name="DeliveryDate">
                  <StyledDatePicker
                    size="large"
                    format="MM/DD/YY h:mm a"
                    showTime={{ minuteStep: 10 }}
                    showNow={false}
                    disabled={datePickerDisabled}
                    disabledDate={disabledDate}
                    allowClear={false}
                  />
                </SettingsFormItem>
                <SettingsFormItem name="DelayedOption">
                  <StyledRadioInput
                    label="Per-User Optimization"
                    defaultVal={delayOptions.default}
                    options={delayOptions.options}
                    onChange={(e) => setTimePickerDisabled(e.target.value !== 'timezone')}
                  />
                </SettingsFormItem>
                <SettingsFormItem name="TimeZoneTime">
                  <StyledDatePicker
                    size="large"
                    picker="time"
                    format="h:mm a"
                    minuteStep={10}
                    allowClear={false}
                    disabled={timePickerDisabled}
                    getPopupContainer={(triggerNode) => triggerNode}
                  />
                </SettingsFormItem>
              </>
            )}
            <PublishButton type="submit" disabled={readOnly} $marginTop={schedulingEnabled ? '0px' : '20px'}>
              {selectedNotification ? 'Publish Changes' : 'Publish Push Notification'}
            </PublishButton>
          </Form>
          {readOnly && schedulingEnabled && (
            <PushNotificationLog
              oneSignalNotifications={oneSignalNotifications}
              setIsComposing={setIsComposing}
              selectedNotification={selectedNotification}
              setSelectedNotification={setSelectedNotification}
              readOnly={readOnly}
              previewValues={previewValues}
            />
          )}
        </>
      ) : (
        <PushNotificationLog
          oneSignalNotifications={oneSignalNotifications}
          setIsComposing={setIsComposing}
          selectedNotification={selectedNotification}
          setSelectedNotification={setSelectedNotification}
          previewValues={previewValues}
        />
      )}
    </PageContainer>
  );
};
