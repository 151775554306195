import { BuilderProduct } from 'providers';

export const sortProductsByPriceTier = (arr: BuilderProduct[]) => {
  return arr.sort((a, b) => {
    const priceA = a.PriceTier ? parseInt(a.PriceTier) : 0;
    const priceB = b.PriceTier ? parseInt(b.PriceTier) : 0;
    return priceA - priceB;
  });
};

export const getProductDurationInDays = (subscriptionDuration: string): number | undefined => {
  switch (subscriptionDuration) {
    case '1m':
      return 365 / 12;
    case '3m':
      return 365 / 4;
    case '6m':
      return 365 / 2;
    case '1y':
      return 365;
    default:
      return undefined;
  }
};

export const getProductPricePerDay = (product: BuilderProduct, price?: number): number | undefined => {
  const durationInDays = getProductDurationInDays(product.SubscriptionDuration);
  if (!durationInDays || !price) {
    return undefined;
  }

  return price / durationInDays;
};

export const getDurationForPrice = (subscriptionDuration: string): string | undefined => {
  switch (subscriptionDuration) {
    case '1m':
      return 'month';
    case '3m':
      return 'quarter';
    case '6m':
      return '6 months';
    case '1y':
      return 'year';
    default:
      return undefined;
  }
};

export const getProductTrialLabel = (subscriptionTrial: string): string | undefined => {
  switch (subscriptionTrial) {
    case '1w':
      return '7 Day Free Trial';
    case '2w':
      return '14 Day Free Trial';
    case '1m':
      return '1 Month Free Trial';
    default:
      return undefined;
  }
};

export const getStandardizedTitle = (subscriptionDuration: string): string => {
  switch (subscriptionDuration) {
    case '1m':
      return 'MONTHLY';
    case '3m':
      return 'QUARTERLY';
    case '6m':
      return '6 MONTHLY';
    case '1y':
      return 'ANNUAL';
    default:
      return '';
  }
};
