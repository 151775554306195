import { useEffect } from 'react';

import { Form } from 'antd';
import { SaveFailed } from 'components';
import { useUnsavedChanges, useSaveContext } from 'providers';
import { useSecureAppProperties, useSaveSecureAppProperties } from 'hooks';
import { SecureAppPropertyToSave } from 'api';
import { PREVIEW_USERS_MODULES } from '../const';

export const useMFFSettings = () => {
  const { data, isLoading, isError } = useSecureAppProperties();
  const { saveChanges } = useSaveContext();
  const { setUnsavedChanges } = useUnsavedChanges();
  const saveSecureAppProperties = useSaveSecureAppProperties();
  const [form] = Form.useForm();

  useEffect(() => {
    if (data?.[PREVIEW_USERS_MODULES]) {
      form.setFieldsValue({ previewUsersModules: data[PREVIEW_USERS_MODULES] });
      setUnsavedChanges(false);
    }
  }, [data]);

  const save = async () => {
    setUnsavedChanges(false);

    const secureAppPropertiesToSave: SecureAppPropertyToSave[] = [];
    const fieldsToSave = form.getFieldsValue(true, (meta) => meta.touched);

    for (const field in fieldsToSave) {
      const value = form.getFieldValue(field).replace(/\s+/g, '');
      secureAppPropertiesToSave.push({ name: PREVIEW_USERS_MODULES, value: value });
    }

    if (secureAppPropertiesToSave.length > 0) {
      try {
        await saveSecureAppProperties.mutateAsync(secureAppPropertiesToSave);
      } catch (error) {
        SaveFailed(error as Error);
        setUnsavedChanges(true);
      }
    }
    await saveChanges();
  };
  return {
    data,
    isLoading,
    isError,
    form,
    save,
  };
};
