import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ColumnModal, CustomButton, Segment, SegmentTitle, SettingsTextInput } from 'components';
import {
  FONT_12PX_MEDIUM,
  FONT_12PX_REGULAR,
  FONT_14PX_MEDIUM,
  FONT_14PX_REGULAR,
  FONT_14PX_SEMIBOLD,
  OVERFLOW_ELLIPSIS,
} from 'font';
import { PlusIcon } from 'icons';
import { BuilderVideo, useContent, useSaveContext } from 'providers';
import {
  DANGER_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_4_COLOUR,
  NEUTRAL_5_COLOUR,
  NEUTRAL_7_COLOUR,
  NEUTRAL_8_COLOUR,
} from 'theme';

import { ITEM_TYPE_VIDEO, Resource } from 'api';
import { isUrl } from 'utils';
import { ItemResources } from './ItemResources';

const ACCEPTED_FILE_TYPES = ['pdf', 'png', 'jpg'];

const getCleanUrl = (url: string) => {
  const baseUrl = url.split('?')[0].split('#')[0];
  const lastDotIndex = baseUrl.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return baseUrl;
  }
  const namePart = baseUrl.substring(0, lastDotIndex);
  const extensionPart = baseUrl.substring(lastDotIndex + 1).toLowerCase();
  return `${namePart}.${extensionPart}`;
};

const getFileType = (url: string) => {
  const parts = url.split('.');
  return parts[parts.length - 1];
};

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const Empty = styled.div`
  width: 100%;
  text-align: center;
  padding: 16px;
`;

const EmptyHeading = styled.div`
  ${FONT_14PX_SEMIBOLD};
  color: ${NEUTRAL_10_COLOUR};
  width: 176px;
  margin: auto;
`;

const EmptySubheading = styled(EmptyHeading)`
  ${FONT_14PX_REGULAR};
  color: ${NEUTRAL_8_COLOUR};
  margin-top: 4px;
`;

const ExternalResourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 6px;
`;

const InternalResourcesContainer = styled.div`
  margin-top: 12px;
`;

const ResourceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  color: ${NEUTRAL_7_COLOUR};
  cursor: pointer;

  :not(:last-child) {
    border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
  }

  :hover {
    background-color: ${NEUTRAL_4_COLOUR};
  }
`;

const RowLeft = styled.div`
  display: flex;
  padding: 0 8px;
  max-width: 450px;
`;

const ResourceTitle = styled.div`
  ${FONT_14PX_MEDIUM};
  ${OVERFLOW_ELLIPSIS};
`;

const RowRight = styled.div`
  display: flex;
  align-items: center;
  ${FONT_12PX_MEDIUM};
`;

const TypeLabel = styled.div`
  text-transform: uppercase;
  padding: 0 12px;
`;

const EditButton = styled(CustomButton)`
  &&& {
    padding: 2px 8px;

    :hover {
      background: none;
    }
  }
`;

const DeleteButton = styled(CustomButton)`
  &&& {
    padding: 2px 8px;
  }
`;

const Error = styled.div`
  height: 20px;
  padding-top: 2px;
  ${FONT_12PX_REGULAR};
  color: ${DANGER_COLOUR};
`;

interface WordpressVideoResourcesProps {
  video: BuilderVideo;
}

export const WordpressVideoResources = ({ video }: WordpressVideoResourcesProps) => {
  const { setVideoValue } = useContent();
  const { setVideoValueToSave, getTempId } = useSaveContext();
  const [externalResources, setExternalResources] = useState<Resource[]>([]);
  const [internalResources, setInternalResources] = useState<Resource[]>([]);
  const [title, setTitle] = useState<string>();
  const [url, setUrl] = useState<string>();
  const [editingResourceId, setEditingResourceId] = useState<string | number>();
  const [open, setOpen] = useState(false); // Modal
  const [error, setError] = useState<string>();
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    const external: Resource[] = [];
    const internal: Resource[] = [];

    video.Resources?.forEach((r) => {
      if (isUrl(r.Url)) {
        external.push(r);
      } else {
        internal.push(r);
      }
    });

    setExternalResources(external);
    setInternalResources(internal);
  }, [video.VideoId]);

  useEffect(() => {
    if (isEdited) {
      const resources = [...externalResources, ...internalResources];
      setVideoValue(video.VideoId, 'Resources', resources);
      setVideoValueToSave(video.VideoId, 'Resources', resources);
    }
  }, [isEdited, externalResources, internalResources]);

  const resetModal = () => {
    setTitle(undefined);
    setUrl(undefined);
    setEditingResourceId(undefined);
    setOpen(false);
    setError(undefined);
  };

  const addResource = useCallback(() => {
    setError(undefined);

    if (url && title) {
      if (!isUrl(url)) {
        setError('URL must begin with http:// or https://');
        return;
      }

      const cleanUrl = getCleanUrl(url);
      const type = getFileType(cleanUrl);

      if (!ACCEPTED_FILE_TYPES.includes(type)) {
        setError(`Accepted file types: ${ACCEPTED_FILE_TYPES.map((t) => ` .${t}`)}`);
        return;
      }

      if (editingResourceId) {
        setExternalResources((existing) =>
          existing.map((r) => {
            if (r.ResourceId === editingResourceId) {
              return { ...r, Title: title, Url: url };
            }
            return r;
          }),
        );
      } else {
        setExternalResources((existing) => [
          ...existing,
          {
            Url: cleanUrl,
            Title: title,
            OriginalUrl: null,
            ParentId: video.VideoId.toString(),
            ParentType: ITEM_TYPE_VIDEO,
            Type: type,
            ResourceId: `TempResourceId${getTempId()}`,
          },
        ]);
      }

      setIsEdited(true);
      resetModal();
    }
  }, [url, title, setExternalResources, resetModal, setError, setIsEdited]);

  const deleteResource = useCallback(
    (resourceId: string | number) => {
      setExternalResources((existing) => existing.filter(({ ResourceId }) => ResourceId !== resourceId));
      setIsEdited(true);
    },
    [setExternalResources, setIsEdited],
  );

  return (
    <Segment $marginBottom="40px">
      <HeaderRow>
        <SegmentTitle title="Resources" $marginBottom="0" />
        <ColumnModal
          title={editingResourceId ? 'Edit Resource' : 'Add Resource'}
          cols={3}
          align="right"
          onConfirm={addResource}
          open={open}
          onOpenChange={setOpen}
          hideClose
          hideDialogActionsBorder
          onCancel={resetModal}
          confirmDisabled={!title || !url}
          primaryText={editingResourceId ? 'Save' : 'Add'}
          triggerButton={
            <CustomButton medium tertiaryHighlight icon={<PlusIcon />}>
              Add
            </CustomButton>
          }
        >
          <SettingsTextInput
            label="Title"
            size="middle"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            $marginBottom="28px"
          />
          <SettingsTextInput
            label="URL"
            size="middle"
            placeholder="URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <Error>{error}</Error>
        </ColumnModal>
      </HeaderRow>
      {video.Resources?.length === 0 ? (
        <Empty>
          <EmptyHeading>No resources</EmptyHeading>
          <EmptySubheading>You don't have any resources yet.</EmptySubheading>
        </Empty>
      ) : (
        <>
          {externalResources.length > 0 && (
            <ExternalResourcesContainer>
              {externalResources.map(({ Title, Url, Type, ResourceId }) => (
                <ResourceRow key={ResourceId} onClick={() => window.open(Url, '_blank')}>
                  <RowLeft>
                    <ResourceTitle>{Title}</ResourceTitle>
                  </RowLeft>
                  <RowRight>
                    <TypeLabel>{Type}</TypeLabel>
                    <EditButton
                      small
                      tertiaryHighlight
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingResourceId(ResourceId);
                        setTitle(Title);
                        setUrl(Url);
                        setOpen(true);
                      }}
                    >
                      Edit
                    </EditButton>
                    <DeleteButton
                      small
                      tertiary
                      danger
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteResource(ResourceId);
                      }}
                    >
                      Delete
                    </DeleteButton>
                  </RowRight>
                </ResourceRow>
              ))}
            </ExternalResourcesContainer>
          )}
          {internalResources.length > 0 && (
            <InternalResourcesContainer>
              <ItemResources resources={internalResources} />
            </InternalResourcesContainer>
          )}
        </>
      )}
    </Segment>
  );
};
