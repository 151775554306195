import { css } from 'styled-components';

export const APP_FONT_8PX_REGULAR = css`
  font-size: 8px;
  line-height: 9px;
  font-weight: 400;
`;

export const APP_FONT_8PX_MEDIUM = css`
  font-size: 8px;
  line-height: 9px;
  font-weight: 500;
`;

export const APP_FONT_9PX_MEDIUM = css`
  font-size: 9px;
  line-height: 11px;
  font-weight: 500;
`;

export const APP_FONT_10PX_MEDIUM = css`
  font-size: 10px;
  line-height: 13px;
  font-weight: 500;
`;

export const APP_FONT_10PX_SEMIBOLD = css`
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
`;

export const APP_FONT_11PX_MEDIUM = css`
  font-size: 11px;
  line-height: 13px;
  font-weight: 500;
`;

export const APP_FONT_11PX_SEMIBOLD = css`
  font-size: 11px;
  line-height: 13px;
  font-weight: 600;
`;

export const APP_FONT_12PX_REGULAR = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
`;

export const APP_FONT_12PX_MEDIUM = css`
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
`;

export const APP_FONT_12PX_SEMIBOLD = css`
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
`;

export const APP_FONT_13PX_REGULAR = css`
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
`;

export const APP_FONT_13PX_MEDIUM = css`
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
`;

export const APP_FONT_14PX_REGULAR = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;
export const APP_FONT_14PX_MEDIUM = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`;
export const APP_FONT_14PX_SEMIBOLD = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
`;

export const APP_FONT_14PX_BOLD = css`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
`;

export const APP_FONT_15PX_SEMIBOLD = css`
  font-size: 15px;
  line-height: 19px;
  font-weight: 600;
`;

export const APP_FONT_15PX_REGULAR = css`
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
`;

export const APP_FONT_15PX_MEDIUM = css`
  font-size: 15px;
  line-height: 19px;
  font-weight: 500;
`;

export const APP_FONT_15PX_BOLD = css`
  font-size: 15px;
  line-height: 19px;
  font-weight: 700;
`;

export const APP_FONT_16PX_REGULAR = css`
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
`;

export const APP_FONT_16PX_MEDIUM = css`
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
`;

export const APP_FONT_16PX_SEMIBOLD = css`
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
`;

export const APP_FONT_16PX_BOLD = css`
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
`;

export const APP_FONT_17PX_REGULAR = css`
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
`;

export const APP_FONT_17PX_MEDIUM = css`
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
`;

export const APP_FONT_17PX_BOLD = css`
  font-size: 17px;
  line-height: 23px;
  font-weight: 700;
`;

export const APP_FONT_18PX_MEDIUM = css`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
`;

export const APP_FONT_18PX_SEMIBOLD = css`
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
`;

export const APP_FONT_18PX_BOLD = css`
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
`;

export const APP_FONT_20PX_MEDIUM = css`
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
`;

export const APP_FONT_20PX_BOLD = css`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
`;

export const APP_FONT_22PX_SEMIBOLD = css`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
`;

export const APP_FONT_22PX_BOLD = css`
  font-size: 22px;
  line-height: 28px;
  font-weight: 700;
`;

export const APP_FONT_26PX_BOLD = css`
  font-size: 26px;
  line-height: 32px;
  font-weight: 700;
`;
