// App property keys
export const ENABLED_MODULES_PROPERTY = 'EnabledModules';
// Secure App property keys
export const PREVIEW_USERS_MODULES = 'previewUsersModules';
// Module access options
export const MODULE_ACCESS = [
  { name: 'Disabled', value: 0 },
  { name: 'Preview', value: 2 },
  { name: 'Enabled', value: 1 },
];
