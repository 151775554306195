import styled from 'styled-components';
import {
  HIGHLIGHT_PRIMARY_COLOUR,
  HIGHLIGHT_PRIMARY_HOVER_COLOUR,
  NEUTRAL_1_COLOUR,
  SUCCESS_COLOUR,
  SUCCESS_HOVER_COLOUR,
  WARNING_COLOUR,
} from 'theme';
import { FONT_14PX_REGULAR } from 'font';
import { useSyncTracing } from 'hooks/useSyncTracing';
import { useEffect, useState } from 'react';
import { UISyncIcon, XIcon } from 'icons';
import {
  AnotherCustomColor,
  CustomColor,
  OnClick,
  useAnotherCustomColor,
  useCustomColor,
  usePointerOnClick,
} from 'utils';
import { Spin } from 'antd';
import { useBanner } from 'providers/banner-provider';

const BannerContainer = styled.div<CustomColor & OnClick & AnotherCustomColor & { $complete?: boolean }>`
  position: absolute;
  top: 72px;
  height: 36px;
  left: ${({ $complete }) => ($complete ? 'calc(50% - 238px)' : 'calc(50% - 126px)')};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  z-index: 100;

  background-color: ${useCustomColor};

  :hover {
    background-color: ${useAnotherCustomColor};
  }

  cursor: ${usePointerOnClick};

  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.09);
  border-radius: 48px;

  ${FONT_14PX_REGULAR};
  color: ${NEUTRAL_1_COLOUR};
`;
const SyncIcon = styled(UISyncIcon)`
  font-size: 16px;
  margin-right: 8px;
`;

const RefreshText = styled.span`
  text-decoration: underline;
  margin-left: 4px;
  margin-right: 4px;
`;

const CloseIcon = styled(XIcon)`
  margin-left: 2px;
  font-size: 24px;
`;

export const SyncBanner = () => {
  const [hide, setHide] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [traceTasks, setTraceTasks] = useState<string[]>([]);
  const [complete, setComplete] = useState<boolean>(false);
  const [hasFailure, setHasFailure] = useState<boolean>(false);

  const { data } = useSyncTracing({ enabled: !disabled, staleTime: 10000, refetchInterval: 10000 });
  const { restartBanner, setRestartBanner } = useBanner();

  useEffect(() => {
    if (restartBanner) {
      setDisabled(false); // Reenable sync tracing
      setRestartBanner(false); // Once triggered once, this should turn off again
    }
  }, [restartBanner]);

  // Setup initial tracing, if no ongoing syncs do nothing
  useEffect(() => {
    if (data && !disabled) {
      const ongoing = [...(data.extracting || []), ...(data.importing || []), ...(data.resource_downloading || [])];
      if (ongoing.length === 0) {
        // No ongoing syncs
        setDisabled(true);
        return;
      }
      // Ongoing syncs
      setHide(false); // Show the banner
      const tasks = ongoing.map((e) => e.TaskID);
      setTraceTasks(tasks); // Begin tracing these syncs
    }
  }, [!!data, disabled]);

  // UseEffect to trace the status of ongoing syncs
  useEffect(() => {
    if (disabled || traceTasks?.length === 0) {
      return;
    }
    console.debug('Checking trace tasks', traceTasks, data);

    // Build up sets of running/completed syncs
    const ongoing = new Set<string>();
    const completed = new Set<string>();
    const failed = new Set<string>();
    for (const t of data?.extracting || []) {
      ongoing.add(t.TaskID);
    }
    for (const t of data?.importing || []) {
      ongoing.add(t.TaskID);
    }
    for (const t of data?.resource_downloading || []) {
      ongoing.add(t.TaskID);
    }
    for (const t of data?.complete || []) {
      completed.add(t.TaskID);
    }
    for (const t of data?.failed || []) {
      failed.add(t.TaskID);
    }

    const progress: Record<string, string> = {};
    let allCompleted = true;
    for (const t of traceTasks) {
      if (ongoing.has(t)) {
        // Do nothing as a trace sync is still running
        progress[t] = 'ongoing';
        allCompleted = false;
      } else if (failed.has(t)) {
        progress[t] = 'failed';
        setHasFailure(true);
      } else if (!completed.has(t)) {
        // Sync is neither ongoing nor completed, nor failed, trace is lost it must have crashed
        // Abandon our tracing
        console.debug('SyncBanner', 'Lost the trace', t);
        setTraceTasks([]);
        setDisabled(true);
        setHide(true);
        return;
      } else {
        progress[t] = 'completed';
      }
    }
    console.debug('SyncBanner', progress);

    if (allCompleted) {
      setHide(false); // unhide it again
      setDisabled(true);
      setComplete(true);
    }
  }, [traceTasks, data, disabled]);

  if (hide) {
    return null;
  }

  return !complete ? (
    <BannerContainer $color={HIGHLIGHT_PRIMARY_COLOUR} $anotherColor={HIGHLIGHT_PRIMARY_HOVER_COLOUR}>
      <Spin indicator={<SyncIcon spin />} style={{ color: NEUTRAL_1_COLOUR, height: '20px' }} />
      Your sync is in progress
      <CloseIcon onClick={() => setHide(true)} />
    </BannerContainer>
  ) : !hasFailure ? (
    <BannerContainer
      $color={SUCCESS_COLOUR}
      onClick={() => window.location.reload()}
      $anotherColor={SUCCESS_HOVER_COLOUR}
      $complete
    >
      {'Your content has been synced. Click here to'}
      <RefreshText>{'refresh'}</RefreshText>
      {'the Builder'}
      <CloseIcon
        onClick={(e) => {
          e.stopPropagation();
          setHide(true);
        }}
      />
    </BannerContainer>
  ) : (
    <BannerContainer
      $color={WARNING_COLOUR}
      onClick={() => window.location.reload()}
      $anotherColor={WARNING_COLOUR}
      $complete
    >
      {'Some content failed to sync. Please contact our Support Team.'}
      <CloseIcon
        onClick={(e) => {
          e.stopPropagation();
          setHide(true);
        }}
      />
    </BannerContainer>
  );
};
